import { Component } from 'react'
import React from 'react'


export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error, errorInfo) {

    //TODO log işlemleri
    debugger;


    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      setTimeout(function () {
        window.location.reload(1);
      }, 8000);

      return <ErrorFallback error={error} />
    }

    return this.props.children;
  }
}

const ErrorFallback = ({ error }) => (

    <div className="block-content">
      <div className='surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center'>
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-small mb-3">
              <i className="pi pi-spin pi-spinner mr-2" style={{ fontSize: '1.5rem' }}> </i>
              
            </div>
            <p className="text-blue-600 text-2xl">We're sorry, something went wrong </p>
              {error.message && (
                <span className="text-500">Error is: {error.message}</span>
              )}
          </div>
        </div>
      </div>
    </div>
)

const errorBoundary = (WrappedComponent) => {
  debugger;

  return class extends ErrorBoundary {
    render() {
      const { hasError, error } = this.state

      if (hasError) {
        // You can render any custom fallback UI
        return <ErrorFallback error={error} />
      }

      return <WrappedComponent {...this.props} />
    }
  }
}

export { errorBoundary }