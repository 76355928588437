import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { productProportionsModel } from '../models/productProportions.model';//Model



export function getProductProportionsList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/ProductProportions/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getProductProportionsById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/ProductProportions/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
export function getProductProportionsListBySubAccountId(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/ProductProportions/getlistbysubaccountid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}


export function addProductProportions(productProportionsModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/ProductProportions/`, {
            amount: productProportionsModel.amount,
            currencyTypeId: productProportionsModel.currencyTypeId,
            subAccountId: productProportionsModel.subAccountId,
            insuranceProductId: productProportionsModel.insuranceProductId
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

// export function addProductProportions(productProportionsModel) {
//     return new Promise((res, rej) =>
//         HTTP.post(`${api}/ProductProportions/`, { tl: productProportionsModel.tl, usd: productProportionsModel.usd,
//               eu: productProportionsModel.eu,subAccountId: productProportionsModel.subAccountId, insuranceProductId: productProportionsModel.insuranceProductId})
//             .then(response => {
//                 if (typeof response.data === 'string') return res(response.data); 
//                 return res({});
//             })
//             .catch((error) => {
//                 rej(error);
//             })
//     );
// }

export function updateProductProportions(productProportionsModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/ProductProportions/`, {
            id:productProportionsModel.id,
            amount: productProportionsModel.amount,
            currencyTypeId: productProportionsModel.currencyTypeId,
            subAccountId: productProportionsModel.subAccountId,
            insuranceProductId: productProportionsModel.insuranceProductId
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}
export function deleteProductProportions(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/ProductProportions/`, { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}


