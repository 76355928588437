

function Logout() {
    return (
        localStorage.removeItem('token'),
        localStorage.removeItem('claims'),
        window.location.reload()
    )
}



export default Logout;