import React, { useState, useEffect, useRef, useContext } from 'react';

import { deptsModel } from '../depts/models/depts.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
//import { FileUpload } from 'primereact/fileupload';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';

import { addLocale } from 'primereact/api';

import { getDebtElementersByproductid, updateElementersStatus } from '../depts/services/depts.service';

import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';
import { Tooltip } from 'primereact/tooltip';
import { JwtHelper } from '../../plugins/jwtHelper';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

// import ExchangeRateContext from '../../service/ExchangeRateContext.js';


const Elementer = () => {

    // const exchangeRateData = useContext(ExchangeRateContext);

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [depts, setDepts] = useState([]);

    const [selectedLogs, setSelectedLogs] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [sendLogsDialog, setSendLogsDialog] = useState(false);
    const [error, setError] = useState(null);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);



    addLocale('tr', {
        firstDayOfWeek: 1,
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cuma'],
        dayNamesShort: ['Pzr', 'Pzrts', 'Sal', 'Çar', 'Per', 'Cuma', 'Cumrts'],
        dayNamesMin: ['P', 'Pt', 'S', 'Ç', 'P', 'C', 'Ct'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        monthNamesShort: ['Ock', 'Şub', 'Mart', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Ek', 'Kas', 'Arl'],
        today: 'Bugün',
        clear: 'Temizle'
    });





    //Get---------
    useEffect(() => {

        getDebtElementersByproductid(30, null)
            .then(data => setDepts(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('PoolListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })




    }, [t]);





    //**************************************
    //Logic fonks. START
    //**************************************



    const dateBodyTemplate2 = (rowData) => {

        return formatDate(rowData.issueDate);
    }
    const dateBodyTemplate3 = (rowData) => {

        return formatDate(rowData.policyStartDate);
    }
    const dateBodyTemplate4 = (rowData) => {

        return formatDate(rowData.policyEndDate);
    }



    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }



    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************
    const sendSelectedLogs = () => {
        debugger;
        setSendLogsDialog(false);
        let selectedLogsIds = selectedLogs.map((arr, i) => arr.id);

        updateElementersStatus(selectedLogsIds)
            .then(data => {
                toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('updateElementerStatusSuccess')}`, life: 3000 });

                getDebtElementersByproductid(30, null)
                    .then(data => setDepts(data))
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('ElementerListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    })
            })
            .catch(err => {
                ////debugger;

                setSelectedLogs(null);
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('sendLogsToDebtError') + ': ' + err.response.data}`, life: 5000 });
                //throw new Error(`${err.data}`);
            })



        setSelectedLogs(null);
    }

    //**************************************
    //btn fonks. END
    //**********************

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('Elementer')} </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const verifiedBodyTemplate = (rowData) => {
        //debugger;
        let falseOrTrue = "";
        if (rowData.insuranceProduct === undefined || rowData.insuranceProduct === null || rowData.insuranceProduct.elementer === false) {
            falseOrTrue = "text-pink-500 pi-times-circle";
        }
        else {
            falseOrTrue = "text-green-500 pi-check-circle"
        }

        return <i className={classNames('pi ' + falseOrTrue)}></i>;
    }

    const statusBodyTemplate = (rowData) => {
        //debugger;
        let falseOrTrue = "";
        if (rowData.policyElementerStatusId === undefined || rowData.policyElementerStatusId === null || rowData.policyElementerStatusId === 0
            || rowData.policyElementerStatusId === 2) {
            falseOrTrue = "text-pink-500 pi-times-circle";
        }
        else if (rowData.policyElementerStatusId === 1) {
            falseOrTrue = "text-green-500 pi-check-circle"
        }

        return <i className={classNames('pi ' + falseOrTrue)}></i>;
    }



    const confirmSendSelectedLogs = () => {
        setSendLogsDialog(true);
    }
    const hideSendLogsDialog = () => {
        setSendLogsDialog(false);
    }

    const sendLogsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideSendLogsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sendSelectedLogs} />

        </>
    );


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }

    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateInsuranceProductCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Yenile')} icon="pi pi-refresh" className="p-button-success mr-2" onClick={confirmSendSelectedLogs} disabled={!selectedLogs || !selectedLogs.length} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }


    return (


        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>
                                <div className="font-medium text-2xl text-900">{t('Elementer')}</div>
                                <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('ElementerList')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">
                                            {t('DebtsList')} </span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-3 lg:mt-0">

                                <DbCurrencies />

                                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" /> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-12">

                <div>
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                        <DataTable ref={dt} value={depts} selection={selectedLogs} onSelectionChange={(e) => setSelectedLogs(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="policyElementerStatusId" header={t('Yenilendi')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={statusBodyTemplate}></Column>
                            <Column field="elementer" header={t('Elementer')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={verifiedBodyTemplate}></Column>
                            <Column field="insuranceProduct.insuranceCompany.name" header={t('Company')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="subAccount.name" header={t('SubAccountName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="insuranceByPerson" header={t('InsuranceByPerson')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="insuredPersonName" header={t('InsuredPersonName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="insuredPersonTc" header={t('InsuredPersonTc')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="policyNumber" header={t('PolicyNumber')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="addendumNo" header={t('AddendumNo')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="issueDate" header={t('IssueDate')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }} body={dateBodyTemplate2} ></Column>
                            <Column field="policyStartDate" header={t('PolicyStartDate')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }} body={dateBodyTemplate3}></Column>
                            <Column field="policyEndDate" header={t('PolicyEndDate')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }} body={dateBodyTemplate4}></Column>
                            <Column field="insuranceProduct.name" header={t('InsuranceProductName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="insuranceProduct.productCode" header={t('InsuranceProductCode')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>
                        <Dialog visible={sendLogsDialog} style={{ width: '550px' }} header={t('Confirm')} modal footer={sendLogsDialogFooter} onHide={hideSendLogsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {<span>Seçilen poliçelerin elementerler durumları güncellenecek onaylıyor musunuz ?</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Elementer, comparisonFn);

