import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from '../AppTopbar';
import { AppFooter } from '../AppFooter';
import { AppMenu } from '../AppMenu';
import { AppConfig } from '../AppConfig';

import Dashboard from '../components/dashboard/Dashboard';
import user from '../components/user/user.component';
import group from '../components/group/group.component';
import translate from '../components/translates/translates.components';
import language from '../components/language/language.components';
import operationclaims from '../components/operationClaims/operationClaims.components';
import region from '../components/region/region.component';
import city from '../components/city/city.component';
import insuranceCompany from '../components/insuranceCompany/insuranceCompany.components';
import insuranceProduct from '../components/insuranceProduct/insuranceProduct.components';
import mainAccount from '../components/mainAccount/mainAccount.component';
import subAccount from '../components/subAccount/subAccount.component';
import poolLogs from '../components/poolLogs/poolLogs.component';
import depts from '../components/depts/depts.component';
import elementer from '../components/elementer/elementer.component';

// import crashableComponent  from '../plugins/ErrorBoundaries/CrashableComponent'

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import ErrorBoundary from '../plugins/ErrorBoundaries/ErrorBoundary';


import { JwtHelper } from '../plugins/jwtHelper';
import { useTranslation } from 'react-i18next';


const App = () => {

    const { t } = useTranslation();

    const [layoutMode, setLayoutMode] = useState('overlay');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    //Token kontrol noktası
    useEffect(() => {
        // console.log("jwt decoded", JwtHelper.getJwtObj())

        // console.log("jwt id", JwtHelper.getUserId())
        // console.log("jwt user", JwtHelper.getUser())
        // console.log("jwt Author", JwtHelper.getAuthority())

        if (!JwtHelper.isValid()) {
            localStorage.setItem("token", "");
            setTimeout(() => {
                window.location.reload();
                // window.location.hash = "/giris"
            }, 1000);
        }
    }, []);
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    // export const AdminRoutes = [
    //     { path: '/user', title: 'Users', icon: 'how_to_reg', class: '', claim:"GetUsersQuery" },
    //     { path: '/group', title: 'Groups', icon:'groups', class: '',claim:"GetGroupsQuery" },
    //     { path: '/operationclaim', title: 'OperationClaim', icon:'local_police', class: '', claim:"GetOperationClaimsQuery"},
    //     { path: '/language', title: 'Languages', icon:'language', class: '', claim:"GetLanguagesQuery" },
    //     { path: '/translate', title: 'TranslateWords', icon: 'translate', class: '', claim: "GetTranslatesQuery" },
    //     { path: '/log', title: 'Logs', icon: 'update', class: '', claim: "GetLogDtoQuery" }
    // ]

    const menu = [
        {
            label: 'Dashboard',
            items: [{
                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/', role: ''
            }],
        },
        {
            label: 'Admın',
            items: [
                { label: t('Users'), icon: 'pi pi-fw pi-users', to: '/user', role: 'GetUsersQuery' },
                { label: t('Groups'), icon: 'pi pi-fw pi-share-alt', to: '/group', role: 'GetGroupsQuery' },
                { label: t('OperationClaim'), icon: 'pi pi-fw pi-sitemap', to: '/operationclaim', role: 'GetOperationClaimsQuery' },
                { label: t('Languages'), icon: 'pi pi-fw pi-flag', to: '/language', role: 'GetLanguagesQuery' },
                { label: t('TranslateWords'), icon: 'pi pi-fw pi-flag', to: '/translate', role: 'GetTranslatesQuery' },
                // { label: 'Logs', icon: 'pi pi-fw pi-history', to: '/log', role: 'GetLogDtoQuery' }
            ],
        },
        {
            label: t('Tanımlamalar'),
            items: [
                { label: t('RegionsTitle'), icon: 'pi pi-fw pi-desktop', to: '/region', role: 'GetRegionQuery' },
                { label: t('CitiesTitle'), icon: 'pi pi-fw pi-desktop', to: '/city', role: 'GetCityQuery' },
                { label: t('InsuranceCompanyTitle'), icon: 'pi pi-fw pi-desktop', to: '/insuranceCompany', role: 'GetInsuranceCompanyQuery' },
                { label: t('InsuranceProductTitle'), icon: 'pi pi-fw pi-desktop', to: '/insuranceProduct', role: 'GetInsuranceProductQuery' },
                { label: t('MainAccount'), icon: 'pi pi-fw pi-desktop', to: '/mainAccount', role: 'GetMainAccountQuery' },
                { label: t('SubAccountTitle'), icon: 'pi pi-fw pi-desktop', to: '/subAccount', role: 'GetSubAccountQuery' },
                { label: t('PoolTitle'), icon: 'pi pi-fw pi-desktop', to: '/poolLogs', role: 'GetPoolLogsQuery' },
                { label: t('DebtTitle'), icon: 'pi pi-fw pi-desktop', to: '/depts', role: 'GetPoolLogsQuery' },

            ],
        },
        {
            label: t('Raporlar'),
            items: [
                { label: t('Elementer'), icon: 'pi pi-fw pi-desktop', to: '/elementer', role: 'GetPoolLogsQuery' },         
            ],
        },

    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">

                <div className="layout-main">
                    <ErrorBoundary>
                        <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                        <Route path="/user" component={user} />
                        <Route path="/group" component={group} />
                        <Route path="/translate" component={translate} />
                        <Route path="/language" component={language} />
                        <Route path="/operationclaim" component={operationclaims} />
                        <Route path="/region" component={region} />
                        <Route path="/city" component={city} />
                        <Route path="/insuranceCompany" component={insuranceCompany} />
                        <Route path="/insuranceProduct" component={insuranceProduct} />
                        <Route path="/mainAccount" component={mainAccount} />
                        <Route path="/subAccount" component={subAccount} />
                        <Route path="/poolLogs" component={poolLogs} />
                        <Route path="/depts" component={depts} />
                        <Route path="/elementer" component={elementer} />
                        {/* <Route path="/operationclaim" component={crashableComponent} /> */}


                        {/* <Route path="/personel" component={PersonnelList} /> */}

                    </ErrorBoundary>


                </div>

                <AppFooter layoutColorMode={layoutColorMode} />

            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;
