import React, { useState, useEffect, useRef } from 'react';

import { regionModel } from './models/region.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { MultiSelect } from 'primereact/multiselect';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getRegionList, addRegion, updateRegion, deleteRegion, getRegionById } from '../region/services/region.service';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';

const Region = () => {


    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(regionModel);
    const [regionCity, setRegionCity] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const [regionSaveDialog, setRegionSaveDialog] = useState(false);
    const [regionDeleteDialog, setRegionDeleteDialog] = useState(false);
    const [regionCityDialog, setRegionCityDialog] = useState(false);


    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    const [mSelectCityValue, setMSelectCityValue] = useState(null);

    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    //Get---------
    useEffect(() => {
        getRegionList()
            .then(data => setRegions(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionListGettingError') + ': ' + err.data}`, life: 5000 });
            })

        setSubmitted(a => !a);
    }, [t]);

    //-------Validation(Check)
    const validForm = () => {
        //debugger;
        let tempNoti = [];
        try {

            //Name
            if (region.name === "" || region.name === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }


    //Save--------
    const save = () => {
        //debugger;
        try {
            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {



                if (region.id) {

                    updateRegionData();

                }
                else {

                    addRegionData();
                }

                setRegionSaveDialog(false);
                setRegion(regionModel);
            }

        } catch (error) {
            setRegion(regionModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateRegionData = () => {
        try {

            let _regions = [...regions];
            let _region = { ...region };

            updateRegion(region)
                .then(data => {

                    _regions = addToListByIndex(_regions, _region);
                    setRegions(_regions);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('RegionUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setRegion(regionModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addRegionData = () => {
        try {

            addRegion(region)
                .then(data => {

                    getRegionList()
                        .then(data => setRegions(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetRegionError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('RegionAddSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setRegion(regionModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteRegionData = () => {
        try {

            let _region = { ...region };

            if (regions.some(val => val.id === _region.id)) {
                deleteRegion(_region.id)
                    .then(data => {
                        //debugger;
                        // _regions = _regions.filter(val => val.id !== region.id);
                        getRegionList()
                            .then(data => setRegions(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('RegionDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setRegion(regionModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setRegion(regionModel);
            setRegionDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //Bölgeye Şehir atama atama btn-----
    const saveRegionCityData = () => {
        try {

            let cityIds = mSelectCityValue.map((arr, i) => arr.code);

            // saveRegionCity(region.id, cityIds)
            //     .then(data => {

            //         toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('UserToRegionClaimsSuccess')}`, life: 3000 });

            //     })
            //     .catch(err => {

            //         setRegion(regionModel);
            //         toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('UserToRegionClaimsError') + ': ' + err.data}`, life: 5000 });
            //         //throw new Error(`${err.data}`);
            //     })

            hideCityDialog();
        } catch (error) {

            error.message = error.message + '.  UI:saveRegionClaimsOpr...';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('UserToRegionClaimsError') + ': ' + error.message}`, life: 5000 });
            setRegionCity({});
            setMSelectCityValue({});
            setRegion(regionModel);

            setError(error);
        }
    }


    //**************************************
    //MultiSelect special fonks. START
    //************************************** 
    const selectCityFooterTemplate = () => {
        const selectedItems = mSelectCityValue;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> {t('City')}{length > 1 ? 's' : ''} {t('Selected')}
            </div>
        );
    }

    const selectedItemCityTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span className={`mr-2 pi pi-user-plus`} style={{ width: '15px', height: '12px' }} />
                    <span>{option.name}</span>
                </div>
            );
        }

        return '';
    };

    const itemCityTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <span className={`mr-2 pi pi-user-minus`} style={{ width: '18px', height: '12px' }} />
                <span>{option.name}</span>
            </div>
        );
    };

    //**************************************
    //MultiSelect fonks.. END
    //**************************************







    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < regions.length; i++) {
                if (regions[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setRegion(regionModel);
            setSubmitted(false);
            setRegionSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setRegion(regionModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editRegion = (region) => {
        try {
            //debugger;
            let _region = { ...region };
            setSubmitted(false);

            getRegionById(_region.id)
                .then(data => {
                    setRegion(data);
                })
                .catch(err => {
                    //debugger;
                    setRegion(regionModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionUpdatedError') + ': ' + err.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setRegionSaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }


    const confirmDeleteRegion = (region) => {
        try {

            setRegion(region);
            setRegionDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setRegion(regionModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setRegion(regionModel);
            setSubmitted(false);
            setRegionDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteRegion-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setRegion(regionModel);
            setSubmitted(false);
            setRegionSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const hideRegionCityDialog = () => {
        try {
            setRegionCity({});
            setMSelectCityValue(null);
            setSubmitted(false);
            setRegionCityDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideUsersModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //groupo kullanıcı güncelleme modali kapatma btn-----
    const hideCityDialog = () => {
        try {
            setRegionCity({});
            setMSelectCityValue(null);
            setSubmitted(false);
            setRegionCityDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideUsersModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _region = { ...region };
            _region[`${name}`] = val;

            setRegion(_region);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**********************


    const regionCityDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideRegionCityDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={saveRegionCityData} />
        </>
    );

    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteRegionDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteRegionData} />
        </>
    );

    //header of datatable
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('RegionList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    //action in datatable row
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateRegionCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editRegion(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteRegionCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteRegion(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }





    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateRegionCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>
                                <div className="font-medium text-2xl text-900">{t('Region')}</div>
                                <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('Region')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">{t('RegionList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-3 lg:mt-0">

                                <DbCurrencies />

                                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" /> */}
                            </div>
                        </div>
                    </div>
                </div>



                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                        <DataTable ref={dt} value={regions}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            {/* <Column field="false" header={t('Status')} dataType="boolean" bodyClassName="text-center" style={{ width: '20%', minWidth: '3rem' }} body={verifiedStatusBodyTemplate}></Column> */}
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={regionSaveDialog} style={{ width: '550px' }} header={t('RegionForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>
                            <div className="field">
                                <label htmlFor="name">{t('Name')} </label>
                                <InputText id="name" value={region.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !region.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>

                        <Dialog visible={regionDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteRegionDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {region && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={regionCityDialog} style={{ width: '550px' }} header={t('RegionUsers')} modal className="p-fluid" footer={regionCityDialogFooter} onHide={hideRegionCityDialog}>
                            <div className="field">
                                <MultiSelect value={mSelectCityValue} onChange={(e) => setMSelectCityValue(e.value)} options={regionCity} optionLabel="name" placeholder={t('slctRegionCity')} filter panelFooterTemplate={selectCityFooterTemplate} selectedItems={mSelectCityValue}
                                    itemTemplate={itemCityTemplate} selectedItemTemplate={selectedItemCityTemplate} />
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        </div>
    );

}



const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Region, comparisonFn);

