import React, { useEffect, useRef, useState } from "react";
import Login from "./components/auth/Login";
import Main from "./pages/Main";
import { Toast } from "primereact/toast";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import SessionManager from "./SessionManager";

const App = () => {
    const toast = useRef(null);
    const [isPersonnel, setIsPersonnel] = useState(false);

    useEffect(() => {
        if (
            !(
                localStorage.token === "Undefined" ||
                localStorage.token === "" ||
                localStorage.token === undefined ||
                localStorage.claims === "Undefined" ||
                localStorage.claims === "" ||
                localStorage.claims === undefined
            )
        ) {
            setIsPersonnel(true);
        }
    }, []);


    if (isPersonnel) {
        return (
            <>
                <Toast ref={toast} />
                <Main /> 
                <SessionManager/>
            </>
        );
    }

    return <Login />;
};
export default App;
