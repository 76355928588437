import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { insuranceCompanyModel } from '../models/insuranceCompany.model';//Model



export function getInsuranceCompanyList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/InsuranceCompanies/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getInsuranceCompanyById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/InsuranceCompanies/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addInsuranceCompany(insuranceModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/InsuranceCompanies/`, { name: insuranceModel.name,  accountNo: insuranceModel.accountNo})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateInsuranceCompany(insuranceModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/InsuranceCompanies/`, { 
            id: insuranceModel.id, name: insuranceModel.name, accountNo : insuranceModel.accountNo })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteInsuranceCompany(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/InsuranceCompanies/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}