
export const cityModel = {
    id: 0,
    name: '',
    code:'',
    regionId: 0,
    region : {
        id:0,
        name:''
    }
};
