export const productProportionsModel = {
    id: 0, 
    currencyTypeId:0, 
    tl: '',
    usd: '',
    eu: '',
    subAccountId: 0,
    amount: '',
    insuranceProductId: 0,
    insuranceProduct: {
      id: 0,
      insuranceCompanyId: 0,
      name: '',
      productsCode: '',
      elementer: true,
      insuranceCompany: {
        id: 0,
        name: '',
        accountNo: ''
      }
    },
    currencyType: {
      id: 0,
      name: '',  
    },
}