export const poolLogsModel = {
    insuranceProductId: 0,
    insuranceProductCode: '',
    insuranceProductName: '', 
    customerTypeId:0,
    addendumNo: 0,
    subAccountId: 0,
    tax: 0,
    subAccountName: '',
    subAccountTaxNumber: '',
    subAccountTcNumber: '',
    policyNumber: '',
    issueDate: '',
    policyStartDate: '',
    policyEndDate: '',
    insuredPersonName: '',
    insuredPersonTc: '',
    currencyType: '',
    currencyTypeId: '',
    currencyRate: 0,
    netPremium: 0,
    grossPremium: 0,
    incomingComm: 0,
    subAccountComm: 0,
    subAccountProductCommPer:0,
    insuranceCompanieName:'',
    companyComm:0,
    serviceFee:0,
    debt:0,
    creditCard: '',
    description: '',
    lastUser: '',
    updatedCurrencyRate: 0,
    dataInsertTypeId:3,
};
