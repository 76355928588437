import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { groupModel } from '../../../components/group/models/group.model';//Model


export function getGroupList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Groups/getall`)
            .then(response => {
                // console.log("getGroupList Geldi")
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getOperationClaimsList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/OperationClaims/getall`)
            .then(response => {
                // console.log("getGroupList Geldi")
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}


export function getUserList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Users/getall`)
            .then(response => {
                // console.log("getGroupList Geldi")
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
export function getGroupById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Groups/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getGroupClaims(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/GroupClaims/getgroupclaimsbygroupid/${id}`,)
            .then(response => { 
                //console.log(response);
                //console.log(response.data); 
                // if (typeof response.data === 'string') return res(response.data); 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getGroupUsers(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/UserGroups/getusersingroupbygroupid/${id}`,)
            .then(response => {
       
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addGroup(groupModel) {

    return new Promise((res, rej) =>
        HTTP.post(`${api}/groups/`, { groupName: groupModel.groupName })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateGroup(groupModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/groups/`, { 
            id: groupModel.id,
             groupName: groupModel.groupName })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteGroup(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/Groups/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function saveGroupClaims(groupId, claims) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/GroupClaims`, { GroupId: groupId, ClaimIds: claims })
                .then(response => {
                    if (typeof response.data === 'string') return res(response.data); 
                    return res({});
                })
                .catch((error) => {
                    rej(error);
                })
    );  
}

export function saveGroupUsers(groupId, userIds) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/UserGroups/updatebygroupid`, { GroupId: groupId, UserIds: userIds })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

