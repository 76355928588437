import React, { useState, useEffect, useRef } from 'react';

import { translatesModel } from '../../components/translates/models/translates.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { MultiSelect } from 'primereact/multiselect';
import { JwtHelper } from '../../plugins/jwtHelper';
import { Dropdown } from 'primereact/dropdown';


import { getLanguagesList, getTranslateList, getTranslateListdto, getTranslatesByLang, getById, addTranslate, updateTranslate, deleteTranslate } from '../translates/services/translates.service';

const Translate = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [translates, setTranslates] = useState([]);
    const [translate, setTranslate] = useState(translatesModel);
    const [submitted, setSubmitted] = useState(false);
    const [langs, setLangs] = useState([]);

    //--------Dialogs
    const [translateSaveDialog, setTranslateSaveDialog] = useState(false);
    const [translateDeleteDialog, setTranslateDeleteDialog] = useState(false);

    //--------Globals
    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    //-------Submit
    //const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Multiselect
    const [selectLangsValue, setSelectLangsValue] = useState(null);


    //----------form data Start
    const [isValidatedCode, setisValidatedCode] = useState(true);
    const [isValidatedValue, setisValidatedValue] = useState(true);
    const [isValidatedLangId, setisValidatedLangId] = useState(true);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        getLanguagesList()
            .then(data => {
                setLangs(data.map(function (item, i) {

                    return { name: item.name, code: parseInt(item.id), langCode: item.code };

                }));

            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetLanguagesListError') + ': ' + err.data}`, life: 5000 });
            })



            setSubmitted(a => !a);

    }, [t]);

    //-------Validation(Check)
    const validForm = () => {

        let tempNoti = [];
        try {

            if (selectLangsValue === null || selectLangsValue.code === 0) {

                tempNoti.push({ severity: 'error', summary: t('Languages'), detail: t('Required'), life: 4000, toastId: 'langId' });

                setisValidatedLangId(false);

            } else {
                setisValidatedLangId(true);
            }

            //Code
            if (translate.code === "" || translate.code.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('code'), detail: t('Required'), life: 4000, toastId: 'code' });

                setisValidatedCode(false);

            } else {
                setisValidatedCode(true);
            }

            //Value
            if (translate.value === "" || translate.value.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'value"' });

                setisValidatedValue(false);

            } else {
                setisValidatedValue(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            //   setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }


    //Get---------
    useEffect(() => {

        getTranslateListdto()
            .then(data => setTranslates(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetTranslateListError') + ': ' + err}`, life: 5000 });
            })

            setSubmitted(a => !a);
    }, [t]);

    //Save--------
    const save = () => {
        try {
            debugger;

            let validStatus = validForm();
            //   setIsSubmitted(validStatus);

            if (validStatus) {
                //rıdvan'a sor. Bu kullanım uygun mu ?
                translate.langId = selectLangsValue.code;
                translate.language = selectLangsValue.langCode;

                if (translate.id) {
                    updateTranslateData();
                }
                else {
                    addTranslateData();
                }

                setTranslateSaveDialog(false);
                setTranslate(translatesModel);
            }

        } catch (error) {
            
            setTranslate(translatesModel);
            
            error.message= error.message + '. UI:SaveOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('TranslateSaveError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Update
    const updateTranslateData = () => {
        try {

            let _translates = [...translates];
            let _translate = { ...translate };

            updateTranslate(translate)
                .then(data => {

                    _translates = addToListByIndex(_translates, _translate);
                    setTranslates(_translates);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('TranslateUpdatedSucces')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('TranslateUpdatedError') + ': ' + error}`, life: 5000 });
                })
            setTranslate(translatesModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Add----
    const addTranslateData = () => {
        try {

            addTranslate(translate)
                .then(data => {

                    getTranslateListdto()
                        .then(data => setTranslates(data))
                        .catch(err => {
                            //throw new Error(`${err}`);
                            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${err.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('TranslateAddSuccess')}`, life: 3000 });
                })
                .catch(err => {
          
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('TranslateAddedError') + ': ' + err.data}`, life: 5000 });
                })

            setTranslate(translatesModel);

        } catch (error) {

            error.message = error.message + '.UI:AddOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }
    }

    //Delete----
    const deleteTranslateData = () => {
        try {
            //debugger;
            let _translate = { ...translate };

            if (translates.some(val => val.id === _translate.id)) {
                deleteTranslate(_translate.id)
                    .then(data => {
                        //debugger;
                        // _groups = _groups.filter(val => val.id !== group.id);
                        getTranslateListdto()
                            .then(data => setTranslates(data))
                            .catch(err => {
                               // throw new Error(`${err}`);
                               toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${err.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('TranslateDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {

                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('TranslateDeleteError') + ': ' + error}`, life: 5000 });

                        setTranslate(translatesModel); 

                    })

            }

            // setGroups(_groups);
            setTranslate(translatesModel);
            setTranslateDeleteDialog(false);

        } catch (error) {

            error.message = error.message + '.UI:DeleteTranslate.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }
    }


    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < translates.length; i++) {
                if (translates[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }
    }





    //**************************************
    //Logic fonks. END
    //**************************************



    //**************************************
    //btn fonks. START
    //**************************************

    //Excel btn----
    const exportCSV = () => {
        try {

            dt.current.exportCSV();

        } catch (error) {
            error.message = error.message + '. UI:CSV-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    //translate adı ekleme btn---
    const openNew = () => {
        try {

            setTranslate(translatesModel);
            setSubmitted(false);
            setTranslateSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setTranslate(translatesModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //translate adı güncelleme btn------
    const editTranslate = (translate) => {
        try {
            let _translate = { ...translate };
            setSubmitted(false);

            getById(_translate.id)
                .then(data => {

                    for (let index = 0; index < langs.length; index++) {
                        const element = langs[index];

                        if (element.code === data.langId) {
                            setSelectLangsValue({ name: element.name, code: parseInt(element.code), langCode: element.langCode });
                        }
                    }
                    setTranslate(data)
                })
                .catch(err => {
                    setTranslate(translatesModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('TranslateEditError') + ': ' + error}`, life: 5000 });
                })

            setTranslateSaveDialog(true);

        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //translate adı silme onayı btn----
    const confirmDeleteTranslate = (translate) => {
        try {

            setTranslate(translate);
            setTranslateDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setTranslate(translatesModel);
            setError(error);
        }

    }

    //Silme modali kapatma btn-----
    const hideDeleteTranslatesDialog = () => {
        try {
            setTranslate(translatesModel);
            setSubmitted(false);
            setTranslateDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteTranslate-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveTranslatesDialog = () => {
        try {

            setTranslate(translatesModel);
            setSubmitted(false);
            setTranslateSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _translate = { ...translate };
            _translate[`${name}`] = val;

            setTranslate(_translate);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**************************************


    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveTranslatesDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteTranslatesDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteTranslatesDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteTranslateData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('TranslatesList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editTranslate(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteTranslate(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateUserCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label={t('ExportData')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable ref={dt} value={translates}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="language" header={t('Language')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="code" header={t('Code')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="value" header={t('Value')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={translateSaveDialog} style={{ width: '550px' }} header={t('TranslateForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveTranslatesDialog}>
                            <div className="field">
                                <label htmlFor="langId">{t('Language')} </label>
                                <Dropdown id="langId" value={selectLangsValue} options={langs} onChange={(e) => setSelectLangsValue(e.value)} optionLabel="name" placeholder="Dil Seçin" className={classNames({ 'p-invalid': !isValidatedLangId })} />
                            </div>
                            <div className="field">
                                <label htmlFor="code">{t('Code')} </label>
                                <InputText id="code" value={translate.code} onChange={(e) => onInputChange(e, 'code')} required autoFocus className={classNames({ 'p-invalid': !isValidatedCode })} />
                                {submitted && !translate.code && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="value">{t('Value')} </label>
                                <InputText id="value" value={translate.value} onChange={(e) => onInputChange(e, 'value')} required autoFocus className={classNames({ 'p-invalid': !isValidatedValue })} />
                                {submitted && !translate.code && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>

                        <Dialog visible={translateDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteTranslatesDialogFooter} onHide={hideDeleteTranslatesDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {translate && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Translate, comparisonFn);

