import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { cityModel } from '../models/city.model';//Model



export function getCityList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Cities/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getCityById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Cities/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addCity(cityModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Cities/`, { name: cityModel.name,  code: cityModel.code, regionId : cityModel.regionId})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateCity(cityModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Cities/`, { 
            id: cityModel.id, code:cityModel.code, name: cityModel.name, regionId : cityModel.regionId })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteCity(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/Cities/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}