export const filterModel = {
    pageNumber: 1,
    pageSize: 50,
    propertyName: 'id',
    sortType: true,
    startDate: '0001-01-01T00:00:00',
    endDate: '0001-01-01T00:00:00',
    subAccountId: 0,
    subAccountName:'',
    customerTypeId: 0,
    dataInsertTypeId: 0,
    insuranceProductId: 0,
    search: null,
    mainAccountId: 0,
    showCreditCard: false,
    creditCardsList: {
        id: ''
    },
};
