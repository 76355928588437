import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { currencyTypesModel } from '../models/currencyTypes.model';//Model




export function getCurrencyTypesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/currencyTypes/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getCurrencyTypesById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/currencyTypes/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
 