import React, { useState, useEffect, useRef } from 'react';

import { userModel } from '../../components/user/models/user.Model';//Model
import { passwordModel } from '../../components/user/models/password.model';//Model
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { MultiSelect } from 'primereact/multiselect';

import { getUserList, addUser, saveUserClaims, updateUser, deleteUser, getUserClaims, getUserById, getUserGroupPermissions, saveUserGroupPermissions, saveUserPassword } from '../user/services/user.service';
import { getGroupList } from '../group/services/group.service';

import { getOperationClaimsList } from '../group/services/group.service';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';

import { JwtHelper } from '../../plugins/jwtHelper';



const User = () => {

    //--------dil
    const { t } = useTranslation();

    //--------
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState({});
    const [userClaims, setUserClaims] = useState({});

    const [user, setUser] = useState(userModel);
    const [password, setPassword] = useState(passwordModel);


    //--------Error handler
    const [error, setError] = useState(null);

    //--------
    const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Dialogs
    const [userSaveDialog, setUserSaveDialog] = useState(false);
    const [userClaimsDialog, setUserClaimsDialog] = useState(false);
    const [userGroupsDialog, setUserGroupsDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [changePasswordDialog, setChangePasswordDialog] = useState(false);


    //Multiselect
    const [mSelectUserClaimsValue, setMSelectUserClaimsValue] = useState(null);
    const [mSelectUserGroupsValue, setMSelectUserGroupsValue] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [tempPassword, setTempPassword] = useState(null);



    //----------form data Satrt
    const [isValidatedFirstName, setisValidatedFirstName] = useState(true);
    const [isValidatedLastName, setisValidatedLastName] = useState(true);
    const [isValidatedCitizenId, setisValidatedCitizenId] = useState(true);
    const [isValidatedEmail, setisValidatedEmail] = useState(true);
    const [isValidatedMobilePhones, setisValidatedMobilePhones] = useState(true);
    const [isValidatedAddress, setisValidatedAddress] = useState(true);
    const [isValidatedNotes, setisValidatedNotes] = useState(true);
    const [isValidatedPassword, setisValidatedPassword] = useState(true);
    //----------form data End


    //--------Ref.
    const toast = useRef(null);
    const dt = useRef(null);

    //Get---------
    useEffect(() => {

        getUserList()
            .then(data => setUsers(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });
            });

        setIsSubmitted(a => !a);
    }, [t]);


    var checkTcNum = function (tckn) {
        try {

            // if (tckn.length !== 11) return false;

            // let total = 0;
            // let checkDigit = parseInt(tckn[10]);

            // for (let i = 0; i < 10; i++) {
            //     total += (parseInt(tckn[i]) + 9 - i) % 10;
            // }

            // return (total % 10 === checkDigit);

            return true;

        } catch (error) {
            error.message = error.message + '. UI:CheckTcNum.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });
            setError(error);
            return false;

        }

    };



    const validForm = () => {

        let tempNoti = [];
        try {

            //Fisrt Name
            if (!user.firstName || user.firstName.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('FullName'), detail: t('Required'), life: 4000 });

                setisValidatedFirstName(false);

            } else {
                setisValidatedFirstName(true);
            }
            //Last Name 
            if (!user.lastName || user.lastName.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('lastName'), detail: t('Required'), life: 4000 });

                setisValidatedLastName(false);

            } else {
                setisValidatedLastName(true);
            }
            // citizen validation
            if (!user.citizenId || user.citizenId.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('CitizenId'), detail: t('Required'), life: 4000, toastId: 'CitizenId' });
                setisValidatedCitizenId(false);
            }
            else {

                if (checkTcNum(user.citizenId)) {

                    setisValidatedCitizenId(true);

                } else {
                    tempNoti.push({ severity: 'error', summary: t('CitizenId'), detail: t('HatalıTcKimlik'), life: 4000, toastId: 'CitizenId' });
                    setisValidatedCitizenId(false);
                }
            }

            //email validation
            if (!user.email || user.email.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('Email'), detail: t('Required'), life: 5000 });

                setisValidatedEmail(false);

            }
            else
                if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email))) {

                    tempNoti.push({ severity: 'error', summary: t('Email'), detail: t(`emailValidMsg`), life: 5000 });

                    setisValidatedEmail(false);

                } else {
                    setisValidatedEmail(true);

                }

            //Phone
            if (!user.mobilePhones || user.mobilePhones.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('MobilePhones'), detail: t('Required'), life: 5000 });

                setisValidatedMobilePhones(false);

            } else {
                setisValidatedMobilePhones(true);

                user.mobilePhones = user.mobilePhones.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
            }

            //Address
            if (!user.address || user.address.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('Address'), detail: t('Required'), life: 5000 });

                setisValidatedAddress(false);


            } else {
                setisValidatedAddress(true);
            }

            //Notes
            if (user.notes.length > 450) {

                tempNoti.push({ severity: 'error', summary: t('FullName'), detail: t('Required'), life: 4000, toastId: 'FullName' });

                setisValidatedNotes(false);

            } else {
                setisValidatedNotes(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                //isLetSubmit = false;
                //setIsSubmitted(false);
                return false;

            } else {
                //isLetSubmit = true;
                //setIsSubmitted(true);
                return true;

                //setIsTestText('c');
                //console.log('Console: ' + isSubmitted);
                // setIsSubmitted((isSubmitted) => { console.log(isSubmitted) }); 
            }


        } catch (error) {
            //debugger;
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setisValidatedEmail(false)
            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;
    }

    //Save--------
    const save = () => {
        //debugger;
        try {

            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {

                // let _users = [...users];
                // let _user = { ...user };

                if (user.userId !== 0) {
                    updateUserData();
                }
                else {
                    addUserData();
                }

                setUserSaveDialog(false);
                setUser(userModel);

            }

        } catch (error) {
            setUser(userModel);

            error.message = error.message + '. UI:SaveOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //Update----
    const updateUserData = () => {
        try {

            let _users = [...users];
            let _user = { ...user };

            updateUser(user)
                .then(data => {

                    _users = addToListByIndex(_users, _user);
                    setUsers(_users);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('userSaveSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                    //throw new Error(`${err}`);

                })

            setUser(userModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('userSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addUserData = () => {
        try {
            addUser(user)
                .then(data => {

                    getUserList()
                        .then(data => setUsers(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.data}`, life: 5000 });

                            //throw new Error(err);
                        })

                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('userAddSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('userAddError') + ': ' + err.response.data}`, life: 5000 });
                })

            setUser(user);

        } catch (error) {


            error.message = error.message + '.UI:AddOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('userAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //silme onayı btn----
    const deleteUserData = () => {
        try {

            let _user = { ...user };
            if (users.some(val => val.userId === _user.userId)) {
                deleteUser(_user.userId)
                    .then(data => {
                        //debugger;
                        // _users = _users.filter(val => val.id !== user.id);
                        getUserList()
                            .then(data => setUsers(data))
                            .catch(err => {
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });
                                //throw new Error(`${err}`);
                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('userDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setUser(userModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                        //throw new Error(`${err}`);
                    })
            }

            setUser(userModel);
            setDeleteUserDialog(false);
        } catch (error) {

            error.message = error.message + '.UI:DeleteOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('userDeleteError') + ': ' + error.message}`, life: 5000 });

            setError(error);

        }
    }

    //pass güncelleme işelmelri btn-----
    const updatePasswordData = () => {
        // debugger;
        try {
            let _user = { ...user };
            password.userId = _user.userId;
            // debugger;
            if (password.password === tempPassword) {
                saveUserPassword(password)
                    .then(data => {
                        toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('passwordUpdatedSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setUser(userModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });
                        //throw new Error(`${err}`);
                    })
            } else {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('passwordNotMatch')}`, life: 5000 });
            }
            hideChangePasswordDialog();
        } catch (error) {

            error.message = error.message + '.  UI:saveGroupClaimsOpr...';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('passwordUpdatedError') + ': ' + error.message}`, life: 5000 });

            setUserClaimsDialog({});
            setMSelectUserClaimsValue({});
            setUser(userModel);

            setError(error);
        }
    }
    //groupo yetki güncelleme işelmelri btn-----
    const saveUserClaimsData = () => {
        try {

            let claimsIds = mSelectUserClaimsValue.map((arr, i) => arr.code);
            debugger;
            saveUserClaims(user.userId, claimsIds)
                .then(data => {
                    toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('userClaimsSaveSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    setUser(userModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                    //throw new Error(`${err}`);
                })

            hideUserClaimsDialog();
        } catch (error) {

            error.message = error.message + '.  UI:saveGroupClaimsOpr...';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('userClaimsSaveError') + ': ' + error.message}`, life: 5000 });
            setUserClaimsDialog({});
            setMSelectUserClaimsValue({});
            setUser(userModel);

            setError(error);
        }
    }

    const saveUserGroupsData = () => {
        try {

            let groupsIds = mSelectUserGroupsValue.map((arr, i) => arr.code);
            debugger;
            saveUserGroupPermissions(user.userId, groupsIds)
                .then(data => {


                    toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('userGroupSaveSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    setUser(userModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('userGroupSaveError') + ': ' + err.response.data}`, life: 5000 });
                })

            hideUserGroupsDialog();
        } catch (error) {

            error.message = error.message + '.  UI:saveGroupClaimsOpr...';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setUserClaimsDialog({});
            setMSelectUserClaimsValue({});
            setUser(userModel);

            setError(error);
        }
    }

    //**************************************
    //MultiSelect special fonks. START
    //**************************************

    const selectUserClaimsFooterTemplate = () => {

        const selectedItems = mSelectUserClaimsValue;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> claim{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    const selectUserGroupsFooterTemplate = () => {
        const selectedItems = mSelectUserGroupsValue;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> group{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    const selectedItemUserCalimsTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span className={`mr-2 pi pi-lock-open`} style={{ width: '15px', height: '12px' }} />
                    <span>{option.name}</span>
                </div>
            );
        }

        return '';
    };

    const itemUserCalimsTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <span className={`mr-2 pi pi-lock`} style={{ width: '18px', height: '12px' }} />
                <span>{option.name}</span>
            </div>
        );
    };

    const selectedItemUserGroupsTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span className={`mr-2 pi pi-plus-circle`} style={{ width: '15px', height: '12px' }} />
                    <span>{option.name}</span>
                </div>
            );
        }

        return '';
    };

    const itemUserGroupsTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <span className={`mr-2 pi pi-minus-circle`} style={{ width: '18px', height: '12px' }} />
                <span>{option.name}</span>
            </div>
        );
    };

    //**************************************
    //MultiSelect fonks.. END
    //**************************************




    //************************************** 
    //Logic fonks. START
    //************************************** 

    const addToListByIndex = (list, item) => {
        try {

            if (findIndexById(item.userId) >= 0) {

                const index = findIndexById(item.userId);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;

        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }


    }


    const findIndexById = (id) => {

        try {

            let index = -1;
            for (let i = 0; i < users.length; i++) {
                if (users[i].userId === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }

    }

    //************************************** 
    //Logic fonks. END
    //************************************** 


    //************************************** 
    //btn fonks. START
    //************************************** 

    //Excel btn----
    const exportCSV = () => {
        try {

            dt.current.exportCSV();

        } catch (error) {
            error.message = error.message + '. UI:CSV-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }
    //User ekleme btn---
    const openChangePassword = (user) => {
        try {
            setUser(user)
            setPassword(password);
            setIsSubmitted(false);
            setChangePasswordDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setPassword(password);
            setUser(userModel);
            setIsSubmitted(false);
            setError(error);
        }
    }

    //User ekleme btn---
    const openNew = () => {
        try {
            clearValidForm();
            setUser(userModel);
            setIsSubmitted(false);
            setUserSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setUser(userModel);
            setIsSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editeUser = (user) => {

        try {
            //debugger;
            let _user = { ...user };
            // setIsSubmitted(false);

            getUserById(_user.userId)
                .then(data => setUser(data))
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                    setUser(userModel);
                })

            setUserSaveDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:EditeModel..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }


    }

    //silme onayı btn----
    const confirmDeleteUser = (user) => {

        try {

            setUser(user);
            setDeleteUserDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setUser(userModel);
            setError(error);
        }
    }

    //gorup yetkileri güncelleme btn----
    const editGrupPermissions = (user) => {
        let tempUserGroupsArr = [];

        try {

            setUser({ ...user });
            getGroupList()
                .then(data => {
                    //debugger

                    getUserGroupPermissions(user.userId)
                        .then(data2 => {
                            //tüm yetkileri setle 
                            setUserGroups(data.map(function (item, i) {

                                if (data2.length > 0 && data2.some(val => parseInt(val.id) === parseInt(item.id)))
                                    tempUserGroupsArr.push({ name: item.groupName, code: parseInt(item.id) });

                                return { name: item.groupName, code: parseInt(item.id) }

                            }));

                            //dbdeki yetkileri setle
                            setMSelectUserGroupsValue(tempUserGroupsArr);

                        })
                        .catch(err => {
                            setUserGroups({});
                            setMSelectUserGroupsValue({});
                            setUser(userModel);
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                        })

                })
                .catch(err => {
                    setUserGroups({});
                    setMSelectUserGroupsValue({});
                    setUser(userModel);

                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                    //throw new Error(`${err}.Hata özeti: bilgileri getirilemedi`);
                })

            setUserGroupsDialog(true);

        } catch (error) {

            error.message = error.message + '.  UI:GrupPermissions..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });


            setError(error);
        }
    }

    //gorup yetkileri getirme btn----
    const getUserPermissions = (user) => {
        try {
            let tempUserClaimsArr = [];
            setUser({ ...user });


            getOperationClaimsList()
                .then(data => {

                    getUserClaims(user.userId)
                        .then(data2 => {
                            //tüm yetkileri setle

                            setUserClaims(data.map(function (item, i) {

                                if (data2.length > 0 && data2.some(val => parseInt(val.id) === parseInt(item.id)))
                                    if (item.alias)
                                        tempUserClaimsArr.push({ name: item.alias, code: parseInt(item.id) });
                                    else
                                        tempUserClaimsArr.push({ name: item.name, code: parseInt(item.id) });


                                debugger;
                                if (item.alias)
                                    return { name: item.alias, code: parseInt(item.id) }
                                else
                                    return { name: item.name, code: parseInt(item.id) }

                            }));

                            //dbdeki yetkileri setle
                            setMSelectUserClaimsValue(tempUserClaimsArr);

                        })
                        .catch(err => {
                            setUserClaims({});
                            setMSelectUserClaimsValue({});
                            setUser(userModel);
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                        })

                })
                .catch(err => {
                    setUserClaims({});
                    setMSelectUserClaimsValue({});
                    setUser(userModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                })

        } catch (error) {
            error.message = error.message + '.  UI:GrupPermissions-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Kullanıcı yetkileri görüntüleme btn----
    function editUserClaimsClick(user) {
        try {

            setIsSubmitted(false);

            getUserPermissions(user);

            setUserClaimsDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }


    }

    //Silme modali kapatma btn-----
    const hideDeleteUserDialog = () => {
        try {
            setUser(userModel);
            setIsSubmitted(false);
            setDeleteUserDialog(false);
        } catch (error) {
            error.message = error.message + '.  UI:DeleteUser-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideUserDialog = () => {
        try {
            setIsSubmitted(false);
            setUserSaveDialog(false);
            clearValidForm();
        } catch (error) {
            error.message = error.message + '.  UI:HideUserModal-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }


    //pass güncelleme modali kapatma btn-----
    const hideChangePasswordDialog = () => {
        try {
            setPassword(passwordModel);
            setTempPassword(null)
            setUser(userModel);
            setIsSubmitted(false);
            setChangePasswordDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideClaimsModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //groupo yetki güncelleme modali kapatma btn-----
    const hideUserClaimsDialog = () => {
        try {
            setUserClaims({});
            setMSelectUserClaimsValue(null);
            setIsSubmitted(false);
            setUserClaimsDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideClaimsModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    const hideUserGroupsDialog = () => {
        try {
            setUserGroups({});
            setMSelectUserGroupsValue(null);
            setIsSubmitted(false);
            setUserGroupsDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideClaimsModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    //**------
    const onInputChange = (e, name) => {

        try {
            const val = (e.target && e.target.value) || '';
            let _user = { ...user };
            _user[`${name}`] = val;

            setUser(_user);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onPasswordInputChange = (e, name) => {

        try {
            //debugger;
            const val = (e.target && e.target.value) || '';
            let _password = { ...password };

            _password[`${name}`] = val;

            setPassword(_password);


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onTempPasswordInputChange = (e) => {

        try {
            const val = (e.target && e.target.value) || '';

            if (password.password !== val && password.password.length === val.length) {
                setisValidatedPassword(false);
                toast.current.show({ severity: 'error', summary: `${t('PasswordsDoNotMatch')}`, detail: `${t('PasswordsDoNotMatch')}`, life: 4000 })
            } else if (password.password === val && password.password.length === val.length) {
                setisValidatedPassword(false);
                toast.current.show({ severity: 'success', summary: `${t('PasswordsMatch')}`, detail: `${t('PasswordsMatch')}`, life: 4000 })
            } else if (password.password.length < val.length) {
                toast.current.show({ severity: 'error', summary: `${t('PasswordsDoNotMatch')}`, detail: `${t('PasswordsDoNotMatch')}`, life: 4000 })
            }

            let _tempPassword = { ...tempPassword };
            _tempPassword = val;
            setTempPassword(_tempPassword);


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onInputNumberChange = (e, name) => {

        try {

            const val = e.value || 0;
            let _user = { ...user };
            _user[`${name}`] = val;

            setUser(_user);

        } catch (error) {
            error.message = error.message + '.  UI:InputNumberChange..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }



    const clearValidForm = () => {

        try {

            setisValidatedFirstName(true);
            setisValidatedLastName(true);
            setisValidatedCitizenId(true);
            setisValidatedEmail(true);
            setisValidatedMobilePhones(true);
            setisValidatedAddress(true);
            setisValidatedNotes(true);

        } catch (error) {
            error.message = error.message + '.  UI:clearValid..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }





    //************************************** 
    //btn fonks. END
    //************************************** 


    const claimsUserDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideUserClaimsDialog} />
            <Button label={t('Update')} icon="pi pi-check" className="p-button-text" onClick={saveUserClaimsData} />
        </>
    );

    const changePasswordDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideChangePasswordDialog} />
            <Button label={t('ChangePassword')} icon="pi pi-check" className="p-button-text" onClick={updatePasswordData} />
        </>
    );

    const groupsUserDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideUserGroupsDialog} />
            <Button label={t('Update')} icon="pi pi-check" className="p-button-text" onClick={saveUserGroupsData} />
        </>
    );


    const userDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideUserDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteUserDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteUserData} />
        </>
    );


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('UserList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate1 = (rowData) => {

        return (
            <>
                <div className="actions">

                    {(() => {
                        if (JwtHelper.isRoleExist('UserChangePasswordCommand')) {
                            return (
                                <Button icon="pi pi-key" title={t('ChangePassword')} className="p-button-rounded p-button-help mt-2 ml-2 mr-2" onClick={() => openChangePassword(rowData)} />
                            )
                        }

                        return null;
                    })()}

                </div>
            </>
        );

    }
    const actionBodyTemplate2 = (rowData) => {

        return (
            <>
                <div className="actions">



                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-lock-open" title={t('ClaimsOfUserPermissions')} className="p-button-rounded mt-2 ml-2 mr-2" onClick={() => editUserClaimsClick(rowData)} />
                            )

                        }

                        return null;
                    })()}

                </div>
            </>
        );

    }

    const actionBodyTemplate3 = (rowData) => {

        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateGroupClaimCommand')) {
                            return (
                                <Button icon="pi pi-users" title={t('GroupsOfUserPermissions')} className="p-button-rounded p-button-warning mt-2 ml-2 mr-2" onClick={() => editGrupPermissions(rowData)} />
                            )
                        }

                        return null;
                    })()}

                </div>
            </>
        );

    }

    const actionBodyTemplate4 = (rowData) => {

        return (
            <>
                <div className="actions">

                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editeUser(rowData)} />
                            )
                        }

                        return null;
                    })()}

                </div>
            </>
        );

    }

    const actionBodyTemplate5 = (rowData) => {

        return (
            <>
                <div className="actions">

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteUserCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteUser(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );

    }

 
    const verifiedBodyTemplate = (rowData) => {

        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isActive, 'text-pink-500 pi-times-circle': !rowData.isActive })}></i>;
    }
    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateUserCommand')) {
            return (
                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label={t('ExportData')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }


    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">{t('Suggestions')}</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>{t('AtLeastOneLowerCase')}</li>
                <li>{t('AtLeastOneNumeric')}</li>
                <li>{t('Minimum8characters')}</li>
            </ul>
        </React.Fragment>
    );


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div>
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable ref={dt} value={users}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="userId" header={t('ID')} sortable headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>
                            <Column field="firstName" header={t('FirstName')} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="lastName" header={t('LastName')} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="isActive" header={t('Status')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={verifiedBodyTemplate}></Column>
                            <Column field="email" header={t('Email')} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="citizenId" header={t('TCKN')} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="mobilePhones" header={t('MobilePhones')} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="address" header={t('Address')} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="notes" header={t('Notes')} sortable headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                            <Column header="Parola" body={actionBodyTemplate1} ></Column>
                            <Column header="Bireysel Yetki" body={actionBodyTemplate2} ></Column>
                            <Column header="Grup Yetki" body={actionBodyTemplate3} ></Column>
                            <Column header="Güncelle" body={actionBodyTemplate4} ></Column>
                            <Column header="Sil" body={actionBodyTemplate5} ></Column>
                        </DataTable>

                        {/* Add ve Edit butonunun açtığı dialog */}
                        <Dialog visible={userSaveDialog} style={{ width: '550px' }} header={user.userId === 0 ? t('UserFormAdd') : t('UserFormUpdate')} modal className="p-fluid" footer={userDialogFooter} onHide={hideUserDialog}>
                            <div className="field col">
                                <label htmlFor="citizenId">{t('TCKN')} </label>
                                <InputText id="citizenId" value={user.citizenId} onChange={(e) => onInputChange(e, 'citizenId')} className={classNames({ 'p-invalid': !isValidatedCitizenId })} />
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="fisrtName">{t('FirstName')} </label>
                                    <InputText id="firstName" value={user.firstName} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': !isValidatedFirstName })} />
                                    {/* {!isValidatedFullName && <small className="p-invalid">{t('Required')}</small>} */}
                                </div>
                                <div className="field col">
                                    <label htmlFor="lastName">{t('LastName')} </label>
                                    <InputText id="lastName" value={user.lastName} onChange={(e) => onInputChange(e, 'lastName')} required autoFocus className={classNames({ 'p-invalid': !isValidatedLastName })} />
                                    {/* {!isValidatedFullName && <small className="p-invalid">{t('Required')}</small>} */}
                                </div>

                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="email">{t('Email')}</label>
                                    <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': !isValidatedEmail })} rows={3} cols={20} />
                                    {/* {!isValidatedEmail && <small className="p-invalid">{t('Required')}</small>} */}
                                </div>

                                <div className="field col">
                                    <label htmlFor="mobilePhones">{t('MobilePhones')}</label>
                                    <InputMask id="mobilePhones" mask="(999) 999-9999" value={user.mobilePhones} onChange={(e) => onInputNumberChange(e, 'mobilePhones')} required className={classNames({ 'p-invalid': !isValidatedMobilePhones })} rows={3} cols={20} />
                                    {/* {!isValidatedMobilePhones && <small className="p-invalid">{t('Required')}</small>} */}
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="address">{t('Address')}</label>
                                <InputTextarea id="address" value={user.address} onChange={(e) => onInputChange(e, 'address')} required className={classNames({ 'p-invalid': !isValidatedAddress })} rows={3} cols={20} />
                                {/* {!isValidatedAddress && <small className="p-invalid">{t('Required')}</small>} */}

                            </div>
                            <div className="field">
                                <label htmlFor="notes">{t('Notes')} </label>
                                <InputText id="notes" value={user.notes} onChange={(e) => onInputChange(e, 'notes')} className={classNames({ 'p-invalid': !isValidatedNotes })} />
                            </div>


                        </Dialog>

                        <Dialog visible={deleteUserDialog} style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {user && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={userClaimsDialog} style={{ width: '550px' }} header={t('operationClaims')} modal className="p-fluid" footer={claimsUserDialogFooter} onHide={hideUserClaimsDialog}>

                            <div className="field">

                                <MultiSelect value={mSelectUserClaimsValue} onChange={(e) => setMSelectUserClaimsValue(e.value)} options={userClaims} optionLabel="name" placeholder={t('slctClaimsPermission')} filter panelFooterTemplate={selectUserClaimsFooterTemplate} selectedItems={mSelectUserClaimsValue}
                                    itemTemplate={itemUserCalimsTemplate} selectedItemTemplate={selectedItemUserCalimsTemplate} />
                            </div>

                        </Dialog>

                        <Dialog visible={userGroupsDialog} style={{ width: '550px' }} header={t('groupClaims')} modal className="p-fluid" footer={groupsUserDialogFooter} onHide={hideUserGroupsDialog}>

                            <div className="field">

                                <MultiSelect value={mSelectUserGroupsValue} onChange={(e) => setMSelectUserGroupsValue(e.value)} options={userGroups} optionLabel="name" placeholder={t('slctClaimsGroup')} filter panelFooterTemplate={selectUserGroupsFooterTemplate} selectedItems={mSelectUserGroupsValue}
                                    itemTemplate={itemUserGroupsTemplate} selectedItemTemplate={selectedItemUserGroupsTemplate} />
                            </div>

                        </Dialog>

                        <Dialog visible={changePasswordDialog} style={{ width: '550px' }} header={t('ChangePassword')} modal className="p-fluid" footer={changePasswordDialogFooter} onHide={hideChangePasswordDialog}>

                            {/* {t('ConfirmPassword')} */}
                            <div className="field">
                                {/* <label htmlFor="password">{t('NewPassword')} </label> */}
                                <Password value={password.password} placeholder={t('Password')} onChange={(e) => onPasswordInputChange(e, 'password')} footer={passwordFooter} toggleMask rows={3} cols={20} />

                            </div>
                            <div className="field">
                                <InputText type='password' value={tempPassword} placeholder={t('NewPassword')} onChange={(e) => onTempPasswordInputChange(e)} className={classNames({ 'p-invalid': !setisValidatedPassword })} rows={3} cols={20} />
                            </div>


                        </Dialog>



                    </div>
                </div>
            </div>
        </div>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(User, comparisonFn);