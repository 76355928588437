import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrenciesByDate } from '../../components/currencies/services/currencies.service';


const DbCurrencies = () => {

    //--------Lang
    const { t } = useTranslation();


    const [rates, setRates] = useState([]);

    const [currencyUSD, setCurrencyUSD] = useState([]);
    const [currencyEUR, setCurrencyEUR] = useState([]);
    const [currencyDate, setCurrencyDate] = useState([]);



    useEffect(() => {

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let today = year + "-" + month + "-" + date;
        let todayText = date + "/" + month + "/" + year;


        getCurrenciesByDate(today, "2")
            .then(data => {
                setCurrencyUSD(data.rate);
            })
            .catch(err => {
                setCurrencyUSD("-")
            })

        getCurrenciesByDate(today, "3")
            .then(data => {
                setCurrencyEUR(data.rate);
            })
            .catch(err => {
                setCurrencyEUR("-")
            })

        setCurrencyDate(todayText);

    });

    return (
        <div>

            <div className="flex align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center">
                    <strong><i className="pi pi-dollar mr-2"></i></strong>
                    <span>USD:<strong> {currencyUSD}</strong></span>
                </div>
                <div className="mr-5 flex align-items-center">
                    <strong><i className="pi pi-euro mr-2"></i></strong>
                    <span>EUR:<strong>  {currencyEUR}</strong></span>
                </div>
                <div className="flex align-items-center">
                    <strong><i className="pi pi-calendar-times mr-2"></i> </strong>
                    <span> {t('Date')}:  <strong>{currencyDate}</strong> </span>
                </div>
            </div>



        </div >
    );
};

export default DbCurrencies;