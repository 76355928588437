import  React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Logout from './components/auth/Logout';

const SessionManager = () => {
 
    const [sessionActive, setSessionActive] = useState(true);
    const [showDialog, setShowDialog] = useState(false); 
    const [sessionExpiresAt, setSessionExpiresAt] = useState(null);

    // Oturumun sona ermesini izlemek için bir zamanlayıcı oluşturun
    useEffect(() => {

        let expirationTime = window.localStorage.getItem("expiration");

        if (expirationTime) {
            const timeoutId = setTimeout(() => {
                setSessionActive(false);
                setShowDialog(true);
            }, new Date(expirationTime) - new Date());
            return () => clearTimeout(timeoutId);
        } 
        setSessionExpiresAt(expirationTime);
    }, [sessionExpiresAt]);

    const handleLogout = () => {
        // TODO: Oturumu kapatmak için gerekli kodları burada yazın
        setSessionActive(false);
        setShowDialog(false);
        Logout(); 
    };

    return (
        <>
            {showDialog && (
                <Dialog
                    header="Oturum süreniz dolmuştur"
                    visible={showDialog}
                    modal
                    onHide={() => setShowDialog(false)}
                >
                    <p>1 saat olan oturum süreniz dolmuştur. Tamam'a basarak güvenli çıkış yapabilirsiniz..</p>
                    <Button label="Tamam" onClick={handleLogout} />
                </Dialog>
            )}
            {sessionActive && <p>Oturum açık</p>}
        </>
    );
}


export default SessionManager;
