import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const ContentLoading = () => {

    return (
        <>
            <div className="block-content">
                <div className='surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center'>
                    <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
                        <div className="text-center mb-5">
                            <div className="text-900 text-3xl font-small mb-3">
                                <div style={{ width: '100%' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <ProgressSpinner />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default ContentLoading;
