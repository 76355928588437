import { api } from '../../../constants/String';
import { HTTP } from '../../../axios/axios';
import { languageModel } from '../../../components/language/models/language.model';

//getAll
export function getLanguagesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Languages/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}
//getById
export function getById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Languages/getbyid/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}


//addLanguage
export function addLanguage(languageModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Languages/`,
            { name: languageModel.name, code: languageModel.code })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//updateLanguage
export function updateLanguage(languageModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Languages/`,
            { id: languageModel.id, name: languageModel.name, code: languageModel.code })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//deleteLanguage
export function deleteLanguage(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/Languages/`,  { data: { id: id } })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}