import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";


export function getUserList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Users/getall`)
            .then(response => {
                // console.log("getUserList Geldi")
                return res(response.data);
            })
            .catch((error) => {
                //debugger;

                rej(error);
            }),
    );
}

export function getUserById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Users/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addUser(userModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Users/`, {
            lastName: userModel.lastName,
            firstName: userModel.firstName,  
            email: userModel.email,
            mobilePhones: userModel.mobilePhones,
            address: userModel.address,
            notes: userModel.notes,
            citizenId:userModel.citizenId
        })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateUser(userModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/users/`, {
            userId: userModel.userId,
            lastName: userModel.lastName,
            firstName: userModel.firstName,  
            citizenId: userModel.citizenId,
            email: userModel.email,
            mobilePhones: userModel.mobilePhones,
            address: userModel.address,
            notes: userModel.notes
        })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function deleteUser(userId) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/users/`,  { data: { userId: userId } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function getUserGroupPermissions(userId) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/UserGroups/getusergroupbyuserid/${userId}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getUserClaims(userId) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/UserClaims/getoperationclaimbyuserid/${userId}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}


export function saveUserClaims(userId, claims) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/UserClaims/`, { UserId: userId, ClaimId: claims })
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function saveUserGroupPermissions(userId, groups) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/UserGroups/`, { UserId: userId, GroupId: groups })
            .then(response => {
                return res(response.data); 
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function saveUserPassword(passwordModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Auth/changeuserpassword/`, {userId: passwordModel.userId, password: passwordModel.password })
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}