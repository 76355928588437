import { api } from '../../../constants/String';
import { HTTP } from '../../../axios/axios';
import { poolLogsModel } from '../models/poolLogs.model';//Model



export function getPoolLogList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/PoolLogs/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getPoolLogById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/PoolLogs/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addPoolLog(poolLogsModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/PoolLogs/`, {
            insuranceProductId: poolLogsModel.insuranceProductId,
            insuranceProductCode: poolLogsModel.insuranceProductCode,
            insuranceProductName: poolLogsModel.insuranceProductName,
            customerTypeId: poolLogsModel.customerTypeId,
            addendumNo: poolLogsModel.addendumNo,
            subAccountId: poolLogsModel.subAccountId,
            tax: poolLogsModel.tax,
            subAccountName: poolLogsModel.subAccountName,
            subAccountTaxNumber: poolLogsModel.subAccountTaxNumber,
            subAccountTcNumber: poolLogsModel.subAccountTcNumber,
            policyNumber: poolLogsModel.policyNumber,
            issueDate: poolLogsModel.issueDate,
            policyStartDate: poolLogsModel.policyStartDate,
            policyEndDate: poolLogsModel.policyEndDate,
            insuredPersonName: poolLogsModel.insuredPersonName,
            insuredPersonTc: poolLogsModel.insuredPersonTc,
            currencyType: poolLogsModel.currencyType,
            currencyRate: poolLogsModel.currencyRate,
            currencyTypeId: poolLogsModel.currencyTypeId,
            netPremium: poolLogsModel.netPremium,
            grossPremium: poolLogsModel.grossPremium,
            incomingComm: poolLogsModel.incomingComm,
            subAccountComm: poolLogsModel.incomingComm,
            subAccountProductCommPer: poolLogsModel.subAccountProductCommPer,
            insuranceCompanieName: poolLogsModel.insuranceCompanieName,
            companyComm: poolLogsModel.companyComm,
            serviceFee: poolLogsModel.serviceFee,
            debt: poolLogsModel.debt,
            creditCard: poolLogsModel.creditCard,
            description: poolLogsModel.description,
            lastUser: poolLogsModel.lastUser,
            updatedCurrencyRate: poolLogsModel.updatedCurrencyRate,
            dataInsertTypeId: poolLogsModel.dataInsertTypeId,
            insuranceByPerson:poolLogsModel.insuranceByPerson
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updatePoolLog(poolLogsModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/PoolLogs/`, {
            id: poolLogsModel.id,
            insuranceProductId: poolLogsModel.insuranceProductId,
            insuranceProductCode: poolLogsModel.insuranceProductCode,
            insuranceProductName: poolLogsModel.insuranceProductName,
            customerTypeId: poolLogsModel.customerTypeId,
            addendumNo: poolLogsModel.addendumNo,
            subAccountId: poolLogsModel.subAccountId,
            tax: poolLogsModel.tax,
            subAccountName: poolLogsModel.subAccountName,
            subAccountTaxNumber: poolLogsModel.subAccountTaxNumber,
            subAccountTcNumber: poolLogsModel.subAccountTcNumber,
            policyNumber: poolLogsModel.policyNumber,
            issueDate: poolLogsModel.issueDate,
            policyStartDate: poolLogsModel.policyStartDate,
            policyEndDate: poolLogsModel.policyEndDate,
            insuredPersonName: poolLogsModel.insuredPersonName,
            insuredPersonTc: poolLogsModel.insuredPersonTc,
            currencyType: poolLogsModel.currencyType,
            currencyRate: poolLogsModel.currencyRate,
            currencyTypeId: poolLogsModel.currencyTypeId,
            netPremium: poolLogsModel.netPremium,
            grossPremium: poolLogsModel.grossPremium,
            incomingComm: poolLogsModel.incomingComm,
            subAccountComm: poolLogsModel.subAccountComm,
            subAccountProductCommPer: poolLogsModel.subAccountProductCommPer,
            insuranceCompanieName: poolLogsModel.insuranceCompanieName,
            companyComm: poolLogsModel.companyComm,
            serviceFee: poolLogsModel.serviceFee,
            debt: poolLogsModel.debt,
            creditCard: poolLogsModel.creditCard,
            description: poolLogsModel.description,
            lastUser: poolLogsModel.lastUser,
            updatedCurrencyRate: poolLogsModel.updatedCurrencyRate,
            dataInsertTypeId: poolLogsModel.dataInsertTypeId,
            insuranceByPerson:poolLogsModel.insuranceByPerson
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}


export function deletePoolLog(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/PoolLogs/`, { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}


export function uploadExcelPoolLog(excelFileModel) {

    let formData = new FormData();
    formData.append("FormFile", excelFileModel.formFile);

    return new Promise((res, rej) =>
        HTTP.post(`${api}/PoolLogs/UploadExcelFile`, formData)
            .then(response => {
                debugger;
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}


export function sendLogsToDebt(selectedLogsIds) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/addDebts`, { poolIds: selectedLogsIds })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                debugger;
                rej(error);
            })
    );
}

export function sendDoubleLogsToDebt(selectedLogsIds, double) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/addDebts`, { poolIds: selectedLogsIds, doubleRegistration: double})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                debugger;
                rej(error);
            })
    );
}


