import { api } from '../../../constants/String';
import { HTTP } from '../../../axios/axios';




export function getElementerReportList() {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/getalldebtelementercounts/`,  { dayCounts: [15,30,45]})
            .then(response => {
              debugger;
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}




export function getDebtElementersByProductId(dayCount, insuranceProductId) {
    // debugger;
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Debts/getdebtelementersbyproductid/${dayCount}/${insuranceProductId}`)
            .then(response => {
                // debugger;
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}


export function getSubAccountList(){ 
    debugger;
    return new Promise((res, rej) =>
        HTTP.get(`${api}/SubAccounts/getbymainaccountid?mainAccountId=1`)
            .then(response => {
                debugger;
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    ); 
}

export function getDashboardTotalCount(){ 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Pools/getdashboardtotalcount`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    ); 
}

export function getDashboardPoolLogCount(){ 
    return new Promise((res, rej) =>
        HTTP.get(`${api}/PoolLogs/getdashboardpoollogcounts`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    ); 
}