 
export const userModel = {
    userId: 0,
    firstName: '', 
    lastName: '', 
    email :'',
    mobilePhones: '',
    isActive: true,
    birthDate: '',
    gender: 0,
    recordDate: '',
    address: '',
    notes: '',
    updateContactDate: '',
    citizenId:'',
};

 