import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { regionModel } from '../models/region.model.js';//Model

export function getRegionList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Regions/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getRegionById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Regions/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addRegion(regionModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Regions/`, { name: regionModel.name })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateRegion(regionModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Regions/`, { 
            id: regionModel.id,
             name: regionModel.name })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteRegion(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/Regions/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}