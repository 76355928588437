import React, { useState, useEffect, useRef } from 'react';

import { operationClaimsModel } from '../../components/operationClaims/models/operationclaims.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getById, getOpertationClaimsList, updateOpertationClaims } from '../../components/operationClaims/services/operationclaims.service';

const OperationClaims = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [operationClaims, setOperationClaims] = useState([]);
    const [operationClaim, setOperationClaim] = useState(operationClaimsModel);

    const [submitted, setSubmitted] = useState(false);

    //--------Dialogs
    const [operationClaimsSaveDialog, setOperationClaimsSaveDialog] = useState(false);

    //--------Globals
    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedAlias, setisValidatedAlias] = useState(true);
    const [isValidatedDescription, setisValidatedDescription] = useState(true);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        getOpertationClaimsList()
            .then(data => setOperationClaims(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 5000 });
            });

        setIsSubmitted(a => !a);

    },[t]);


    //-------Validation(Check)
    const validForm = () => {
        let tempNoti = [];
        try {

            //name
            if (!operationClaim.name || operationClaim.name.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('name'), detail: t('Required'), life: 4000, toastId: 'name' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            //Name
            if (!operationClaim.alias || operationClaim.alias.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('alias'), detail: t('Required'), life: 4000, toastId: 'alias' });

                setisValidatedAlias(false);

            } else {
                setisValidatedAlias(true);
            }


            //Name 
            if (!operationClaim.description || operationClaim.description.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('description'), detail: t('Required'), life: 4000, toastId: 'description' });

                setisValidatedDescription(false);

            } else {
                setisValidatedDescription(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';

            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }

    //Save------
    const save = () => {
        try {

            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {

                if (operationClaim.id) {
                    updateOperationClaimsData();
                }
                else {
                    //OpertaionClaimste get yetkisi yok.
                }

                setOperationClaimsSaveDialog(false);
                setOperationClaim(operationClaimsModel);
            }


        } catch (error) {
 
            setOperationClaim(operationClaim);

            error.message = error.message + '. UI:SaveOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Update
    const updateOperationClaimsData = () => {
        try {

            let _operationClaims = [...operationClaims];
            let _operationClaim = { ...operationClaim };

            updateOpertationClaims(operationClaim)
                .then(data => {

                    _operationClaim = addToListByIndex(_operationClaims, _operationClaim);
                    setOperationClaims(_operationClaims);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('operationUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('operationUpdateError') + ': ' + err.response.data}`, life: 5000 });
                })
            setOperationClaim(operationClaimsModel);

        } catch (error) {
       
            error.message = error.message + '. UI:SaveOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }



    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {
        //debugger;
        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }

    }

    const findIndexById = (id) => {
       // debugger;
        try {
            let index = -1;
            for (let i = 0; i < operationClaims.length; i++) {
                if (operationClaims[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);

        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************


    //**************************************
    //btn fonks. START
    //**************************************

    //Excel btn----
    const exportCSV = () => {
        try {

            dt.current.exportCSV();

        } catch (error) {
            error.message = error.message + '. UI:CSV-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Operation Claims güncelleme btn------
    const editOperationClaims = (operationClaim) => {
        try {

            let _operationClaim = { ...operationClaim };
            setSubmitted(false);

            getById(_operationClaim.id)
                .then(data => {
                    setOperationClaim(data)
                })
                .catch(err => {
                    setOperationClaim(operationClaim);
                    toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${err.response.data}`, life: 5000 });

                    //throw new Error(`${err}`);
                })

            setOperationClaimsSaveDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:EditeModel..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveOperationClaimsDialog = () => {
        try {

            setOperationClaim(operationClaimsModel);
            setSubmitted(false);
            setOperationClaimsSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _operationClaim = { ...operationClaim };
            _operationClaim[`${name}`] = val;

            setOperationClaim(_operationClaim);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**************************************




    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveOperationClaimsDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('OperationClaimsList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateOperationClaimCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editOperationClaims(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label={t('ExportData')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <DataTable ref={dt} value={operationClaims}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="alias" header={t('Alias')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="description" header={t('Description')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={operationClaimsSaveDialog} style={{ width: '550px' }} header={t('OperationClaimsForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveOperationClaimsDialog}>
                            <div className="field">
                                <label htmlFor="name">{t('Name')} </label>
                                <InputText id="name" value={operationClaim.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !operationClaim.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="alias">{t('Alias')} </label>
                                <InputText id="alias" value={operationClaim.alias} onChange={(e) => onInputChange(e, 'alias')} required className={classNames({ 'p-invalid': !isValidatedAlias })} />
                                {submitted && !operationClaim.alias && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="description">{t('Description')} </label>
                                <InputText id="description" value={operationClaim.description} onChange={(e) => onInputChange(e, 'description')} required className={classNames({ 'p-invalid': !isValidatedDescription })} />
                                {submitted && !operationClaim.description && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        </div>
    );











}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OperationClaims, comparisonFn);

