import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './i18next';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

import App from './App';






ReactDOM.render(
    <Suspense fallback={(
        <div className="block-content">
            <div className='surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center'>
                <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
                    <div className="text-center mb-5">
                        <div className="text-900 text-3xl font-small mb-3">
                            <i className="pi pi-spin pi-spinner mr-2" style={{ fontSize: '1.5rem' }}> </i>
                            Admin Panel
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}>
        <HashRouter>
            <ScrollToTop>
                <App></App>
            </ScrollToTop>
        </HashRouter>
    </Suspense>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();