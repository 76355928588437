import React, { useState, useEffect, useRef } from 'react';

import { mainAccountModel } from './models/mainAccount.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { MultiSelect } from 'primereact/multiselect';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getAccountTypesList, getMainAccountList, addMainAccount, updateMainAccount, deleteMainAccount, getMainAccountById } from './services/mainAccount.service';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';


const MainAccount = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [mainAccounts, setMainAccounts] = useState([]);
    const [mainAccount, setMainAccount] = useState(mainAccountModel);
    const [submitted, setSubmitted] = useState(false);
    const [AccountTypes, setAccountTypes] = useState([]);

    const [mainAccountSaveDialog, setMainAccountSaveDialog] = useState(false);
    const [mainAccountDeleteDialog, setMainAccountDeleteDialog] = useState(false);


    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);


    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Multiselect
    const [selectAccountTypesValue, setSelectAccountTypesValue] = useState(null);

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedCode, setisValidatedCode] = useState(true);
    const [isValidatedAccountTypeId, setisValidatedAccountTypeId] = useState(true);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    //Get---------
    useEffect(() => {
        getMainAccountList()
            .then(data => setMainAccounts(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getAccountTypesList()
            .then(data => {
                setAccountTypes(data.map(function (item, i) {
                    //console.log(item.name + " - " + item.id)
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('accountTypeListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        setSubmitted(a => !a);
    }, [t]);

    //-------Validation(Check)
    const validForm = () => {
        //debugger;
        let tempNoti = [];
        try {

            //Name
            if (mainAccount.name === "" || mainAccount.name === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            //AccountTypeId
            if (mainAccount.accountTypeId === "" ||
                mainAccount.accountTypeId === 0 ||
                selectAccountTypesValue === null ||
                selectAccountTypesValue.code === "" ||
                selectAccountTypesValue.code === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'accountTypeId"' });

                setisValidatedAccountTypeId(false);

            } else {
                setisValidatedAccountTypeId(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }

    //Save--------
    const save = () => {
         //debugger;
        try {

            if (!(selectAccountTypesValue === null ||
                selectAccountTypesValue.code === "" ||
                selectAccountTypesValue.code === 0)) {

                mainAccount.accountTypeId = selectAccountTypesValue.code;
                mainAccount.accountType = {
                    id: selectAccountTypesValue.code,
                    name: selectAccountTypesValue.name
                };

            }

            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {

                if (mainAccount.id) {

                    updateMainAccountData();

                }
                else {

                    addMainAccountData();
                }

                setMainAccountSaveDialog(false);
                setMainAccount(mainAccountModel);
            }

        } catch (error) {
            setMainAccount(mainAccountModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateMainAccountData = () => {
        try {
            debugger;
            let _mainAccounts = [...mainAccounts];
            let _mainAccount = { ...mainAccount };

            updateMainAccount(mainAccount)
                .then(data => {

                    _mainAccounts = addToListByIndex(_mainAccounts, _mainAccount);
                    setMainAccounts(_mainAccounts);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('MainAccountUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setMainAccount(mainAccountModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addMainAccountData = () => {
        try {

            //debugger;
            addMainAccount(mainAccount)
                .then(data => {

                    getMainAccountList()
                        .then(data => setMainAccounts(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountAddError') + ': ' + err.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('MainAccountAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setMainAccount(mainAccountModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteMainAccountData = () => {
        try {

            let _mainAccount = { ...mainAccount };

            if (mainAccounts.some(val => val.id === _mainAccount.id)) {
                deleteMainAccount(_mainAccount.id)
                    .then(data => {
                        ////debugger;
                        // _mainAccounts = _mainAccounts.filter(val => val.id !== mainAccount.id);
                        getMainAccountList()
                            .then(data => setMainAccounts(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('MainAccountDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setMainAccount(mainAccountModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setMainAccount(mainAccountModel);
            setMainAccountDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }





    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < mainAccounts.length; i++) {
                if (mainAccounts[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setMainAccount(mainAccountModel);
            setSubmitted(false);
            setMainAccountSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setMainAccount(mainAccountModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editMainAccount = (mainAccount) => {
        try {
            debugger;
            let _mainAccount = { ...mainAccount };
            setSubmitted(false);

            getMainAccountById(_mainAccount.id)
                .then(data => {
                    for (let index = 0; index < AccountTypes.length; index++) {
                        const element = AccountTypes[index];

                        if (element.code === data.accountTypeId) {
                            setSelectAccountTypesValue({ name: element.name, code: parseInt(element.code) });
                        }
                    }
                    setMainAccount(data);
                })
                .catch(err => {
                    ////debugger;
                    setMainAccount(mainAccountModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountUpdatedError') + ': ' + err.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setMainAccountSaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }


    const confirmDeleteMainAccount = (mainAccount) => {
        try {

            setMainAccount(mainAccount);
            setMainAccountDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setMainAccount(mainAccountModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setMainAccount(mainAccountModel);
            setSubmitted(false);
            setMainAccountDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteMainAccount-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setMainAccount(mainAccountModel);
            setSubmitted(false);
            setMainAccountSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _mainAccount = { ...mainAccount };
            _mainAccount[`${name}`] = val;

            setMainAccount(_mainAccount);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**********************



    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteMainAccountDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteMainAccountData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('MainAccountList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateMainAccountCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editMainAccount(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteMainAccountCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteMainAccount(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }





    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateMainAccountCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>
                                <div className="font-medium text-2xl text-900">{t('MainAccount')}</div>
                                <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('MainAccount')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">{t('MainAccountList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-3 lg:mt-0">

                                <DbCurrencies />

                                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" /> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                        <DataTable ref={dt} value={mainAccounts}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            {/* <Column field="code" header={t('code')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column> */}
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            {/* <Column field="accountTypeId" header={t('accountTypeId')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column> */}
                            <Column field="accountType.name" header={t('AccountTypeName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            {/* <Column field="false" header={t('Status')} dataType="boolean" bodyClassName="text-center" style={{ width: '20%', minWidth: '3rem' }} body={verifiedStatusBodyTemplate}></Column> */}
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={mainAccountSaveDialog} style={{ width: '550px' }} header={t('MainAccountForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>

                            <div className="field">
                                <label htmlFor="accountTypeId">{t('AccountType')} </label>
                                <Dropdown id="accountTypeId" value={selectAccountTypesValue} options={AccountTypes} onChange={(e) => setSelectAccountTypesValue(e.value)} optionLabel="name" placeholder="Hesap Tipi Seçin" className={classNames({ 'p-invalid': !isValidatedAccountTypeId })} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">{t('Name')} </label>
                                <InputText id="name" value={mainAccount.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !mainAccount.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>

                        <Dialog visible={mainAccountDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteMainAccountDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {mainAccount && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>



                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MainAccount, comparisonFn);

