import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

/*Service*/
import { LoginUser } from '../../service/User';

const Login = () => {

    const { t, i18n } = useTranslation();

    function handleClick(lang) {
        i18n.changeLanguage(lang.code);
        setDropdownValue(lang);
    }

    const [dropdownValue, setDropdownValue] = useState(null); //Dil seçimi - select
    const dropdownValues = [
        { name: 'Türkçe', code: 'tr-TR' },
        { name: 'İngilizce', code: 'en-US' },
    ];



    const [data, setData] = useState({
        email: localStorage.loginUserName,
        password: localStorage.loginUserpassword,
    });
    const [checked, setChecked] = useState(); //Giriş bilgilerimi hatırla
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
   

    useEffect(() => {
        if (localStorage.loginUserInfoCheck === 'true') {
            setChecked(true)
        }
    }, []);

    const login = () => {
        setIsLoading(true)
        LoginUser(data.email, data.password).then(res => {
            setIsLoading(false)
            if (checked) {
                localStorage.setItem("loginUserInfoCheck", checked);
                localStorage.setItem("loginUserName", data.email);
                localStorage.setItem("loginUserpassword", data.password);
            }
            else {
                localStorage.setItem("loginUserInfoCheck", '');
                localStorage.setItem("loginUserName", '');
                localStorage.setItem("loginUserpassword", '');
            }
            setTimeout(() => {
                window.location.reload();
            }, 1000);

            // console.log("cevap geldi=>", res)
             toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('LoginSuccess')}`, life: 3000 });

        }).catch(error => {
            setIsLoading(false);
            // console.log("cevap geldi=>", err.response.data)
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('LoginError') + ': ' + error.response.data}`, life: 5000 });
        })
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }

    const onKeyUpValue = (event) => {
        if (event.keyCode === 13) {
            login()
        }
    }

    return (

        <>
            {isLoading ? ( 
                <ContentLoading /> 
            ) : (
                <div className="block-content">
                    <div className='surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center'>
                        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
                            <Toast ref={toast} />
                            <div className="text-center mb-5">
                                <img src="images/blocks/logos/logo.svg" alt="logo" height="50" className="mb-3" style={{ maxWidth: '100%' }} />
                                <div className="text-900 text-3xl font-medium mb-3 mt-2">{t('Welcome')}</div>
                             </div>

                            <div>
                                <label htmlFor="email1" className="block text-900 font-medium mb-2">{t('Email')}</label>
                                <InputText id="email1" type="text" className="w-full mb-3" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />

                                <label htmlFor="password1" className="block text-900 font-medium mb-2">{t('Password')}</label>
                                <InputText onKeyUp={onKeyUpValue.bind(this)} id="password1" type="password" className="w-full mb-3" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />

                                <label htmlFor="slcLang" className="block text-900 font-medium mb-2">{t('SelectLanguage')}</label>
                                <Dropdown id="slcLang" value={dropdownValue} onChange={(e) => handleClick(e.value)} options={dropdownValues} optionLabel="name" className="w-full mb-3" placeholder={t('SelectLanguage')} />

                                <label htmlFor="email1" className="block text-900 font-medium mb-2">{t('RememberMe')}</label>
                                <div className="flex align-items-center justify-content-between mb-6">
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="rememberme1" binary className="mr-2" onChange={e => setChecked(e.checked)} checked={checked} />
                                        <label htmlFor="rememberme1">{t('RememberMyLogin')}</label>
                                    </div>
                                </div>

                                <Button label={t('Login')} icon="pi pi-user" onClick={login} style={{ width: '100%' }} className={"w-full" + data.email && data.password ? " " : "p-disabled"} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Login, comparisonFn);