
export const subAccountModel = {
    id: 0,
    name: '',
    tc: '',
    taxNo: '',
    address: '',
    description: '',
    cityId: 0,
    accountTypeId: 0,
    accountStatusId: 0,
    userId: 0,
    mainAccountId: 0,
    insuranceProductId: 0,
    customerTypeId:0,
    city: {
        id: 0,
        name: ''
    },
    accountStatus: {
        id: 0,
        name: ''
    },
    user: {
        userId: 0,
        firstName: '',
        lastName: '',

    },
    mainAccount: {
        id: 0,
        name: ''
    },
    productProportion: {
        tl: 0,
        usd: 0,
        eu: 0,
        insuranceProductId: 0
    },
    customerType: {
        id: 0,
        name: ''
    }
};
