import { api } from '../../../constants/String';
import { HTTP } from '../../../axios/axios';
import { poolsModel } from '../models/pools.model';//Model
import { filterModel } from '../../poolLogs/models/filterModel';



export function getPoolList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Pools/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getPoolById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Pools/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
 
export function filterPools(filterModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Pools/getlistforpaging`, {
            pageNumber: filterModel.pageNumber,
            pageSize: filterModel.pageSize,
            propertyName: filterModel.propertyName,
            sortType: true,
            startDate: filterModel.startDate,
            endDate: filterModel.endDate,
            subAccountId: filterModel.subAccountId,
            customerTypeId: filterModel.customerTypeId,
            dataInsertTypeId: filterModel.dataInsertTypeId,
            insuranceProductId: filterModel.insuranceProductId,
            search: filterModel.search,
            showCreditCard : filterModel.showCreditCard,
            creditCardsList: filterModel.creditCardsList
        })
            .then(response => {  
                debugger;
                return res(response.data); 
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function addPool(poolsModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Pools/`, {
            insuranceProductId: poolsModel.insuranceProductId,
            insuranceProductCode: poolsModel.insuranceProductCode,
            insuranceProductName: poolsModel.insuranceProductName,
            customerTypeId: poolsModel.customerTypeId,
            addendumNo: poolsModel.addendumNo,
            subAccountId: poolsModel.subAccountId,
            tax: poolsModel.tax,
            subAccountName: poolsModel.subAccountName,
            subAccountTaxNumber: poolsModel.subAccountTaxNumber,
            subAccountTcNumber: poolsModel.subAccountTcNumber,
            policyNumber: poolsModel.policyNumber,
            issueDate: poolsModel.issueDate,
            policyStartDate: poolsModel.policyStartDate,
            policyEndDate: poolsModel.policyEndDate,
            insuredPersonName: poolsModel.insuredPersonName,
            insuredPersonTc: poolsModel.insuredPersonTc,
            currencyType: poolsModel.currencyType,
            currencyRate: poolsModel.currencyRate,
            currencyTypeId: poolsModel.currencyTypeId,
            netPremium: poolsModel.netPremium,
            grossPremium: poolsModel.grossPremium,
            incomingComm: poolsModel.incomingComm,
            subAccountComm: poolsModel.incomingComm,
            subAccountProductCommPer: poolsModel.subAccountProductCommPer,
            insuranceCompanieName: poolsModel.insuranceCompanieName,
            companyComm: poolsModel.companyComm,
            serviceFee: poolsModel.serviceFee,
            debt: poolsModel.debt,
            creditCard: poolsModel.creditCard,
            description: poolsModel.description,
            lastUser: poolsModel.lastUser,
            dataInsertTypeId: poolsModel.dataInsertTypeId,
            insuranceCompanyAccountNo: poolsModel.insuranceCompanyAccountNo
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updatePool(poolsModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Pools/`, {
            id: poolsModel.id,
            insuranceProductId: poolsModel.insuranceProductId,
            insuranceProductCode: poolsModel.insuranceProductCode,
            insuranceProductName: poolsModel.insuranceProductName,
            customerTypeId: poolsModel.customerTypeId,
            addendumNo: poolsModel.addendumNo,
            subAccountId: poolsModel.subAccountId,
            tax: poolsModel.tax,
            subAccountName: poolsModel.subAccountName,
            subAccountTaxNumber: poolsModel.subAccountTaxNumber,
            subAccountTcNumber: poolsModel.subAccountTcNumber,
            policyNumber: poolsModel.policyNumber,
            issueDate: poolsModel.issueDate,
            policyStartDate: poolsModel.policyStartDate,
            policyEndDate: poolsModel.policyEndDate,
            insuredPersonName: poolsModel.insuredPersonName,
            insuredPersonTc: poolsModel.insuredPersonTc,
            currencyType: poolsModel.currencyType,
            currencyRate: poolsModel.currencyRate,
            currencyTypeId: poolsModel.currencyTypeId,
            netPremium: poolsModel.netPremium,
            grossPremium: poolsModel.grossPremium,
            incomingComm: poolsModel.incomingComm,
            subAccountComm: poolsModel.subAccountComm,
            subAccountProductCommPer: poolsModel.subAccountProductCommPer,
            companyComm: poolsModel.companyComm,
            serviceFee: poolsModel.serviceFee,
            debt: poolsModel.debt,
            creditCard: poolsModel.creditCard,
            description: poolsModel.description,
            lastUser: poolsModel.lastUser,
            dataInsertTypeId: poolsModel.dataInsertTypeId,
            insuranceCompanyAccountNo: poolsModel.insuranceCompanyAccountNo            
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}
export function deletePool(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/Pools/`, { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}


export function sendFindDataLogs(selectedLogsIds) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Pools/UpdateSubAccountOfPools`, { poolIds: selectedLogsIds })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function getAllCreditCards() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Pools/getallcreditcards`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function updateSubAccountManual(filter) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Pools/updatesubacoountmanual`, {subAccountId:filter.subAccountId, poolIds: filter.poolIds })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateSubaccountAllOfPools() {
    debugger;
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Pools/updatesubaccountallofpools`, {})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}
