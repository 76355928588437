import { api } from '../../../constants/String';
import { HTTP } from '../../../axios/axios';
import { translatesModel } from '../../../components/translates/models/translates.model';




export function getLanguagesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Languages/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//getall
export function getTranslateList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Translates/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//getTranslateListdto
export function getTranslateListdto() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Translates/gettranslatelistdto`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//getTranslatesByLang
export function getTranslatesByLang(lang) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Translates/gettranslatesbylang/${lang}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//getById
export function getById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Translates/getbyid/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//addTranslate
export function addTranslate(translatesModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Translates/`,
            { langId: translatesModel.langId, value:translatesModel.value, code: translatesModel.code })
            .then(response => {   
                return res(response.data);                
            })
            .catch((error) => { 
                rej(error.response);
            })
    );
}


//updateTranslate
export function updateTranslate(translatesModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Translates/`,
            { id: translatesModel.id, langId: translatesModel.langId, value:translatesModel.value, code: translatesModel.code })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

//deleteTranslate
export function deleteTranslate(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/Translates/`,  { data: { id: id } })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}