import {api} from '../../../constants/String';
import {HTTP} from '../../../axios/axios';
import {operationClaimsModel} from '../../../components/operationClaims/models/operationclaims.model';

export function getOpertationClaimsList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/OperationClaims/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function getById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/OperationClaims/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function updateOpertationClaims(operationClaimsModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/OperationClaims/`,
            { id: operationClaimsModel.id, alias: operationClaimsModel.alias, description:operationClaimsModel.description})
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}