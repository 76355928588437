import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { subAccountModel } from '../models/subAccount.model';//Model



export function getAccountStatusList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/AccountStatuses/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getSubAccountList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/SubAccounts/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getSubAccountById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/SubAccounts/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getSubAccountListByMainAccountId(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/SubAccounts/getbymainaccountid?mainAccountId=${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addSubAccount(subAccountModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/SubAccounts/`, {
            cityId: subAccountModel.cityId,
            accountStatusId: subAccountModel.accountStatusId,
            customerTypeId: subAccountModel.customerTypeId,
            mainAccountId: subAccountModel.mainAccountId,
            userId: subAccountModel.userId,
            name: subAccountModel.name,
            tc: subAccountModel.tc,
            taxNo: subAccountModel.taxNo,
            address: subAccountModel.address,
            description: subAccountModel.description
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateSubAccount(subAccountModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/SubAccounts/`, {
            id: subAccountModel.id,
            cityId: subAccountModel.cityId,
            accountStatusId: subAccountModel.accountStatusId,
            customerTypeId: subAccountModel.customerTypeId,
            mainAccountId: subAccountModel.mainAccountId,
            userId: subAccountModel.userId,
            name: subAccountModel.name,
            tc: subAccountModel.tc,
            taxNo: subAccountModel.taxNo,
            address: subAccountModel.address,
            description: subAccountModel.description
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}
export function deleteSubAccount(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/SubAccounts/`, { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}


export function getSubAccountsByFiltre(filterSubAccountModel) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.post(`${api}/SubAccounts/getallbyfilters`, {
            cityId: filterSubAccountModel.cityId,
            accountStatusId: filterSubAccountModel.accountStatusId,
            customerTypeId: filterSubAccountModel.customerTypeId,
            mainAccountId: filterSubAccountModel.mainAccountId,
            searchText: filterSubAccountModel.searchText
        })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}