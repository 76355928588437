
export const mainAccountModel = {
    id: 0,
    name: '', 
    accountTypeId: 0,
    accountType : {
        id:0,
        name:''
    }
};
