import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { customerTypesModel } from '../models/customerTypes.model';//Model



export function getCustomerTypeList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/CustomerTypes/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getCustomerTypeById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/CustomerTypes/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addCustomerType(customerTypesModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/CustomerTypes/`, { name: customerTypesModel.name})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateCustomerType(customerTypesModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/CustomerTypes/`, { 
            id: customerTypesModel.id, name: customerTypesModel.name})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteCustomerType(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/CustomerTypes/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}