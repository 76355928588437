export const insuranceProductModel = {
    id: 0,
    name: '',
    productCode: '',
    elementer: [],
    insuranceCompanyId:0,
    insuranceCompany: {
        id: 0,
        name: '',
        accountNo: ''
    }

};
