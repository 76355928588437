export const deptsModel = {
    insuranceProductId: 0,
    insuranceProductCode: '',
    insuranceProductName: '',
    customerTypeId: 0,
    addendumNo: 0,
    subAccountId: 0,
    tax: 0,
    subAccountName: '',
    subAccountTaxNumber: '',
    subAccountTcNumber: '',
    policyNumber: '',
    issueDate: '0001-01-01T00:00:00',
    policyStartDate: '0001-01-01T00:00:00',
    policyEndDate: '0001-01-01T00:00:00',
    insuredPersonName: '',
    insuredPersonTc: '',
    currencyType: '',
    currencyTypeId: '',
    currencyRate: 0,
    netPremium: 0,
    grossPremium: 0,
    incomingComm: 0,
    subAccountComm: 0,
    subAccountProductCommPer: 0,
    insuranceCompanieName: '',
    companyComm: 0,
    serviceFee: 0,
    debt: 0,
    creditCard: '',
    description: '',
    lastUser: '',
    dataInsertTypeId: 0,
    currencyDate: '',
    movementAmount: 0,
    movementTypeId: 0,
    deptCurrencyType: '',
    deptCurrencyTypeId: 0,
    deptCurrencyRate: 0,
    deptCurrencyDate: '',
    incomingAmount: 0,
    movementTypeName: '', 
    policyElementerStatusId:0,
    insuranceProduct: {
        insuranceCompany: {
            name: ''
        },
        name:''
    },
    subAccount:{
        name:''
    },
    movementType:{
        name:''
    }


};
