import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { insuranceProductModel } from '../models/insuranceProduct.model';//Model



export function getInsuranceProductList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/InsuranceProducts/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getInsuranceProductById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/InsuranceProducts/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addInsuranceProduct(insuranceProductModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/InsuranceProducts/`, {insuranceCompanyId: insuranceProductModel.insuranceCompanyId, name: insuranceProductModel.name, productCode: insuranceProductModel.productCode, elementer: insuranceProductModel.elementer})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateInsuranceProduct(insuranceProductModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/InsuranceProducts/`, { 
            id: insuranceProductModel.id,
            insuranceCompanyId: insuranceProductModel.insuranceCompanyId,
            name: insuranceProductModel.name,
            productCode: insuranceProductModel.productCode,
            elementer: insuranceProductModel.elementer})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteInsuranceProduct(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/InsuranceProducts/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}