import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { agencyOfficersModel } from '../models/agencyOfficers.model';//Model




export function getAgencyOfficersList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/agencyOfficers/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getAgencyOfficersById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/agencyOfficers/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getAgencyOfficersListBySubAccountId(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/AgencyOfficers/getalistbysubaccountid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
export function addAgencyOfficers(agencyOfficersModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/AgencyOfficers/`, {
            companyId: 1, subAccountId: agencyOfficersModel.subAccountId, firstName: agencyOfficersModel.firstName,
            lastName: agencyOfficersModel.lastName, title: agencyOfficersModel.title, email: agencyOfficersModel.email, phoneNumber: agencyOfficersModel.phoneNumber
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateAgencyOfficers(agencyOfficersModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/AgencyOfficers/`, {id: agencyOfficersModel.id, companyId: 1, subAccountId: agencyOfficersModel.subAccountId, firstName: agencyOfficersModel.firstName,
            lastName: agencyOfficersModel.lastName, title: agencyOfficersModel.title, email: agencyOfficersModel.email, phoneNumber: agencyOfficersModel.phoneNumber
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}
export function deleteAgencyOfficers(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/AgencyOfficers/`, { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}