import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { mainAccountModel } from '../models/mainAccount.model';//Model


export function getAccountTypesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/AccountTypes/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getMainAccountList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/MainAccounts/getall`)
            .then(response => { 
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getMainAccountById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/MainAccounts/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function addMainAccount(mainAccountModel) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/MainAccounts/`, { name: mainAccountModel.name, accountTypeId : mainAccountModel.accountTypeId})
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function updateMainAccount(mainAccountModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/MainAccounts/`, { 
            id: mainAccountModel.id, name: mainAccountModel.name, accountTypeId : mainAccountModel.accountTypeId })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                 return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
} 
export function deleteMainAccount(id) {
    return new Promise((res, rej) =>
    HTTP.delete(`${api}/MainAccounts/`,  { data: { id: id } })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data); 
                return res({});
            })
            .catch((error) => {
                rej(error);
            })
    );
}