
export const agencyOfficersModel = {
    id: 0,
    companyId: 0,
    subAccountId: 0,
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: ''
};
