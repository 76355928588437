import { api } from '../constants/String';
import { HTTP } from "../axios/axios";

export function LoginUser(email, password) {

    return new Promise((res, rej) =>
        HTTP.post(`${api}/auth/login`, { email, password })
            .then(response => {
                //setMessage('success', 'Successfully logged in');
                debugger;
                localStorage.setItem("token", response.data.data.token)
                localStorage.setItem("claims", response.data.data.claims);
                localStorage.setItem("expiration", response.data.data.expiration)
                //localStorage.setItem("lang", "tr-TR");

                return res(response.data);
            })
            .catch((error) => {

                // console.log("error=>", error)
                //setMessage('error', message);
                rej(error);
            }),
    );
}




