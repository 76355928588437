import React, { useState, useEffect, useRef } from 'react';

import { cityModel } from './models/city.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { MultiSelect } from 'primereact/multiselect';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getCityList, addCity, updateCity, deleteCity, getCityById } from './services/city.service';
import { getRegionList } from '../region/services/region.service';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';


const City = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [citys, setCitys] = useState([]);
    const [city, setCity] = useState(cityModel);
    const [submitted, setSubmitted] = useState(false);
    const [regions, setRegions] = useState([]);

    const [citySaveDialog, setCitySaveDialog] = useState(false);
    const [cityDeleteDialog, setCityDeleteDialog] = useState(false);


    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);


    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Multiselect
    const [selectRegionsValue, setSelectRegionsValue] = useState(null);

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedCode, setisValidatedCode] = useState(true);
    const [isValidatedRegionId, setisValidatedRegionId] = useState(true);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    //Get---------
    useEffect(() => {
        getCityList()
            .then(data => setCitys(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getRegionList()
            .then(data => {
                setRegions(data.map(function (item, i) {
                    //console.log(item.name + " - " + item.id)
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('RegionListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        setSubmitted(a => !a);
    }, [t]);

    //-------Validation(Check)
    const validForm = () => {
        //debugger;
        let tempNoti = [];
        try {

            //Name
            if (city.name === "" || city.name === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }
            //Code
            if (city.code === "" || city.code === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedCode(false);

            } else {
                setisValidatedCode(true);
            }
            //RegionId
            if (city.regionId === "" || city.regionId === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'regionId"' });

                setisValidatedRegionId(false);

            } else {
                setisValidatedRegionId(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }

    //Save--------
    const save = () => {
        //debugger;
        try {
            city.regionId = selectRegionsValue.code;
            city.region = {
                id: selectRegionsValue.code,
                name: selectRegionsValue.name
            };

            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {


                if (city.id) {

                    updateCityData();

                }
                else {

                    addCityData();
                }

                setCitySaveDialog(false);
                setCity(cityModel);
            }

        } catch (error) {
            setCity(cityModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CitySaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateCityData = () => {
        try {
            //debugger;
            let _citys = [...citys];
            let _city = { ...city };

            updateCity(city)
                .then(data => {

                    _citys = addToListByIndex(_citys, _city);
                    setCitys(_citys);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('CityUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setCity(cityModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addCityData = () => {
        try {

            addCity(city)
                .then(data => {

                    getCityList()
                        .then(data => setCitys(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('CityAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setCity(cityModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteCityData = () => {
        try {

            let _city = { ...city };

            if (citys.some(val => val.id === _city.id)) {
                deleteCity(_city.id)
                    .then(data => {
                        //debugger;
                        // _citys = _citys.filter(val => val.id !== city.id);
                        getCityList()
                            .then(data => setCitys(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetCityDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('CityDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setCity(cityModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setCity(cityModel);
            setCityDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }





    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < citys.length; i++) {
                if (citys[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setCity(cityModel);
            setSubmitted(false);
            setCitySaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setCity(cityModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editCity = (city) => {
        try {
            //debugger;
            let _city = { ...city };
            setSubmitted(false);

            getCityById(_city.id)
                .then(data => {
                    for (let index = 0; index < regions.length; index++) {
                        const element = regions[index];

                        if (element.code === data.regionId) {
                            setSelectRegionsValue({ name: element.name, code: parseInt(element.code) });
                        }
                    }
                    setCity(data);
                })
                .catch(err => {
                    //debugger;
                    setCity(cityModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityUpdatedError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setCitySaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CityUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }


    const confirmDeleteCity = (city) => {
        try {

            setCity(city);
            setCityDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setCity(cityModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setCity(cityModel);
            setSubmitted(false);
            setCityDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteCity-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setCity(cityModel);
            setSubmitted(false);
            setCitySaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _city = { ...city };
            _city[`${name}`] = val;

            setCity(_city);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**********************



    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteCityDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteCityData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('CityList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateCityCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editCity(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteCityCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteCity(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }





    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateCityCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">


                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>
                                <div className="font-medium text-2xl text-900">{t('City')}</div>
                                <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('City')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">{t('CityList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-3 lg:mt-0">

                                <DbCurrencies />

                                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" /> */}
                            </div>
                        </div>
                    </div>
                </div>



                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                        <DataTable ref={dt} value={citys}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            {/* <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column> */}
                            <Column field="code" header={t('Code')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            {/* <Column field="regionId" header={t('regionId')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column> */}
                            <Column field="region.name" header={t('RegionName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            {/* <Column field="false" header={t('Status')} dataType="boolean" bodyClassName="text-center" style={{ width: '20%', minWidth: '3rem' }} body={verifiedStatusBodyTemplate}></Column> */}
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={citySaveDialog} style={{ width: '550px' }} header={t('CityForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>

                            <div className="field">
                                <label htmlFor="regionId">{t('Region')} </label>
                                <Dropdown id="regionId" value={selectRegionsValue} options={regions} onChange={(e) => setSelectRegionsValue(e.value)} optionLabel="name" placeholder="Bölge Seçin" className={classNames({ 'p-invalid': !isValidatedRegionId })} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">{t('Name')} </label>
                                <InputText id="name" value={city.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !city.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="code">{t('Code')} </label>
                                <InputText id="code" value={city.code} onChange={(e) => onInputChange(e, 'code')} required autoFocus className={classNames({ 'p-invalid': !isValidatedCode })} />
                                {submitted && !city.code && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            {/* <div className="field">
                                <label htmlFor="region">{t('Region')} </label>
                                <InputText id="regionId" value={city.regionId} onChange={(e) => onInputChange(e, 'regionId')} required autoFocus className={classNames({ 'p-invalid': !isValidatedRegionId })} />
                                {submitted && !city.regionId && <small className="p-invalid">{t('Required')}</small>}
                            </div> */}
                        </Dialog>

                        <Dialog visible={cityDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteCityDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {city && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>



                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(City, comparisonFn);

