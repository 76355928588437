import React, { useState, useEffect, useRef } from 'react';

// Prime Component
import { Chart } from 'primereact/chart';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { getElementerReportList, getSubAccountList, getDashboardTotalCount, getDashboardPoolLogCount } from './services/dashboard.service';
import { Toast } from 'primereact/toast';
import { Link } from "react-router-dom";




// Component



const Dashboard = (props) => {
    //--------Lang
    const { t } = useTranslation();

    const [lineOptions, setLineOptions] = useState(null);
    const [products, setProducts] = useState(null);
    const [chartElmenterData, setChartElmenterDatas] = useState([]);
    const [subAccountLength, setSubAccountLength] = useState(null);
    const [poolLength, setPoolLength] = useState(null);
    const [poolLogOneDayLength, setPoolLogOneDayLength] = useState(null);
    const [poolLogSevenDayLength, setPoolLogSevenDayLength] = useState(null);
    const [poolLogThirtyDayLength, setPoolLogThirtyDayLength] = useState(null);
    const [poolLogOneDay, setPoolLogOneDay] = useState(null);
    const [poolLogSevenDay, setPoolLogSevenDay] = useState(null);
    const [poolLogThirtyDay, setPoolLogThirtyDay] = useState(null);

    const [toDate, setToDate] = useState(null);
    const [toWeek, setToWeek] = useState(null);
    const [toMonth, setToMonth] = useState(null);


    const lineData = {
        labels: ['15 Gün', '30 Gün', '45 Gün'],
        datasets: chartElmenterData
    };

    const [chartOptions, setChartOptions] = useState({});
    const [chartDatas, setChartDatas] = useState({});


    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const formatCurrency = (value, currencyType = "TRY") => {

        if (value === undefined) {
            return 0;
        }

        return value.toLocaleString('tr-TR', { style: 'currency', currency: currencyType }).replace(',', '.');
    };

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    useEffect(() => {

        var bugun = new Date();

        // Bu haftanın başlangıç tarihi (bugünün pazartesi günü olup olmadığını kontrol ederek bulunur)
        var buHaftaBaslangic = new Date(bugun);
        buHaftaBaslangic.setDate(bugun.getDate() - bugun.getDay() + (bugun.getDay() === 0 ? -6 : 1));

        // Bu haftanın bitiş tarihi (hafta başlangıcına 6 gün eklenerek bulunur)
        var buHaftaBitis = new Date(buHaftaBaslangic);
        buHaftaBitis.setDate(buHaftaBaslangic.getDate() + 6);

        // Bu ayın başlangıç tarihi
        var buAyBaslangic = new Date(bugun.getFullYear(), bugun.getMonth(), 1);

        // Bu ayın bitiş tarihi (ayın son günü alınarak bulunur)
        var buAyBitis = new Date(bugun.getFullYear(), bugun.getMonth() + 1, 0);



        setToDate(formatDate(bugun));
        setToWeek(formatDate(buHaftaBaslangic) + " - " + formatDate(buHaftaBitis));
        setToMonth(formatDate(buAyBaslangic) + " - " + formatDate(buAyBitis));


        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
        const chartArr = [];

        getElementerReportList()
            .then(data => {
                debugger
                // let _products = { ...products };
                setProducts(data);


                const grouproduct = groupBy(data, product => product.id);
                const even15 = (element) => element.dayCount === 15;
                const even30 = (element) => element.dayCount === 30;
                const even45 = (element) => element.dayCount === 45;

                const keys = Array.from(grouproduct.keys()); // Map anahtarlarını bir diziye dönüştürün


                for (let index = 0; index < keys.length; index++) {

                    const key = keys[index];
                    const tempGroupProduct = grouproduct.get(key);
                    const tempCountProduct = [];


                    if (tempGroupProduct.some(even15)) {
                        tempCountProduct.push(tempGroupProduct.find(even15).elementerCount);
                    } else {
                        tempCountProduct.push(0);
                    }

                    if (tempGroupProduct.some(even30)) {
                        tempCountProduct.push(tempGroupProduct.find(even30).elementerCount);
                    } else {
                        tempCountProduct.push(0);
                    }

                    if (tempGroupProduct.some(even45)) {
                        tempCountProduct.push(tempGroupProduct.find(even45).elementerCount);
                    } else {
                        tempCountProduct.push(0);
                    }

                    let randomColor = getRandomColor();
                    const tempObject = {
                        label: tempGroupProduct[0].insuranceCompany.name + " - " + tempGroupProduct[0].name + " - " + tempGroupProduct[0].productCode,
                        data: tempCountProduct,
                        fill: false,
                        backgroundColor: randomColor,
                        borderColor: randomColor,
                        tension: .4
                    }

                    chartArr.push(tempObject);


                }

                setChartElmenterDatas(chartArr);
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('ElementerListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })


        const dataSetsData = [];
        const labelsData = [];
        const colorsData = [];
        const documentStyle = getComputedStyle(document.documentElement);

        getSubAccountList()
            .then(data => {

                const grouproduct = groupBy(data, product => product.cityId);

                console.log(grouproduct);
                for (let index = 1; index <= 81; index++) {
                    if (grouproduct.get(index) !== undefined) {
                        labelsData.push(grouproduct.get(index)[0].city.name);
                        dataSetsData.push(grouproduct.get(index).length);
                        colorsData.push(getRandomColor());
                    }
                }


                const tempObject = {
                    labels: labelsData,
                    datasets: [
                        {
                            data: dataSetsData,
                            backgroundColor: colorsData,
                            hoverBackgroundColor: colorsData
                        }
                    ]
                }
                setSubAccountLength(data.length)
                setChartDatas(tempObject)
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('Acentalar Getirilemedi.') + ': ' + err.response.data}`, life: 5000 });
            })




        getDashboardTotalCount()
            .then(data => {

                var say = 0;
                for (var i = 0; i < data.length; i++) {
                    say += parseInt(data[i].count);
                }

                setPoolLength(say);
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('ElementerListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })


        getDashboardPoolLogCount()
            .then(data => {
                debugger;

                var pol1Say = 0;
                var pol7Say = 0;
                var pol30Say = 0;
                var totalGrossOneDay = "";
                var totalGrossSevenDay = "";
                var totalGrossThirtyDay = "";

                if (data.length > 0) {

                    const grouproduct = groupBy(data, x => x.dayCounts);

                    const days = ['1', '7', '30'];

                    days.forEach((day, index) => {

                        var totalGrossPremium = "";
                        var polSay = 0;
                        if (grouproduct.get(day) !== undefined) {
                            for (var i = 0; i < grouproduct.get(day).length; i++) {
                                var formatGrossPremium = formatCurrency(parseFloat(grouproduct.get(day)[i].totalGrossPremium), grouproduct.get(day)[i].currencyTypeName);
                                polSay += parseInt(grouproduct.get(day)[i].count);
                                totalGrossPremium += formatGrossPremium + " - ";
                            }
                            totalGrossPremium = totalGrossPremium.slice(0, -2)
                        }

                        if (day === '1') {
                            pol1Say = polSay;
                            totalGrossOneDay = totalGrossPremium;
                        }
                        if (day === '7') {
                            pol7Say = polSay;
                            totalGrossSevenDay = totalGrossPremium;
                        }
                        if (day === '30') {
                            pol30Say = polSay;
                            totalGrossThirtyDay = totalGrossPremium;
                        }


                    });


                }
                setPoolLogOneDayLength(pol1Say);
                setPoolLogOneDay(totalGrossOneDay);

                setPoolLogSevenDayLength(pol7Say);
                setPoolLogSevenDay(totalGrossSevenDay);

                setPoolLogThirtyDayLength(pol30Say);
                setPoolLogThirtyDay(totalGrossThirtyDay);





            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('getDashboardPoolLogCountError') + ': ' + err.response.data}`, life: 5000 });
            })



        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartOptions(options);

    }, [props.colorModel], [t]);

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    return (

        <div className="grid">
            <Toast ref={toast} />

            {/* <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                      {<ExchangeRates/>}
                    </div>
                    <span className="text-green-500 font-medium">24 new </span>
                    <span className="text-500">since last visit</span>
                </div>
            </div> */}
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Havuz Anlık Poliçe Sayısı</span>
                            <div className="text-900 font-medium text-xl">{poolLength}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Anlık poliçe sayısını göstermektedir.</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Bugüne Ait Toplam Poliçe Sayısı</span>

                            <div className="text-900 font-medium text-xl">{poolLogOneDayLength} Poliçe  | <span className="text-green-500 font-medium"> {poolLogOneDay} </span></div>

                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{toDate}</span>
                    <span className="text-500"> toplam poliçe sayısı</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Son 1 Haftadaki Poliçe Sayısı</span>
                            <div className="text-900 font-medium text-xl">{poolLogSevenDayLength} Poliçe  | <span className="text-green-500 font-medium"> {poolLogSevenDay} </span></div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{toWeek} </span>
                    <span className="text-500">toplam poliçe sayısı</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Son 1 Aylık Poliçe Sayısı</span>
                            <div className="text-900 font-medium text-xl">{poolLogThirtyDayLength} Poliçe  | <span className="text-green-500 font-medium"> {poolLogThirtyDay} </span></div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{toMonth} </span>
                    <span className="text-500"> toplam poliçe sayısı</span>
                </div>
            </div>
            <div className='col-4 xl:coL-4'>
                <div className="card">
                    <Link to="/elementer"><h5>{t('ElementerReport')}</h5></Link>

                    <DataTable ref={dt} value={products} rows={5} paginator responsiveLayout="scroll">
                        <Column field="insuranceCompany.name" header={t('InsuranceCompany')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        <Column field="name" header={t('InsuranceProduct')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        <Column field="elementerCount" header={t('Count')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        <Column field="dayCount" header={t('DayCount')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>

                        {/* <Column header="Image" body={(data) => <img className="shadow-2" src={``} alt={data.image} width="50" />} />
                        <Column field="name" header="Name" sortable style={{ width: '35%' }} /> */}
                        {/* <Column field="price" header="Price" sortable style={{ width: '35%' }} body={(data) => formatCurrency(data.price)} /> */}
                        {/* <Column
                            header= {t('View')}
                            style={{ width: '15%' }}
                            body={() => (
                                <>
                                    <Button icon="pi pi-search" type="button" className="p-button-text" />
                                </>
                            )}
                        /> */}
                    </DataTable>
                </div>
            </div>
            <div className='col-4 xl:col-4'>
                <div className="card">
                    <h5>{t('ElementerReportChart')}</h5>
                    <Chart type="line" data={lineData} options={lineOptions} />
                </div>
            </div>
            <div className='col-4 xl:col-4'>
                <div className="card">
                    <h5>Acentalarım</h5>
                    <div className="card flex justify-content-center">
                        <Chart type="pie" data={chartDatas} options={chartOptions} className="w-20rem" />
                    </div>
                    <span className="text-500">Toplam Acenta Sayısı:</span>
                    <span className="text-green-500 font-medium"> {subAccountLength}  </span>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
