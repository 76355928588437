import React, { useState, useEffect, useRef } from 'react';

import { insuranceCompanyModel } from './models/insuranceCompany.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast'; 
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary'; 
import { JwtHelper } from '../../plugins/jwtHelper';

import { getInsuranceCompanyList, addInsuranceCompany, updateInsuranceCompany, deleteInsuranceCompany, getInsuranceCompanyById } from '../insuranceCompany/services/insuranceCompany.service'; 
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';

const InsuranceCompany = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [insuranceCompanys, setInsuranceCompanys] = useState([]);
    const [insuranceCompany, setInsuranceCompany] = useState(insuranceCompanyModel);
    const [submitted, setSubmitted] = useState(false); 

    const [insuranceCompanySaveDialog, setInsuranceCompanySaveDialog] = useState(false);
    const [insuranceCompanyDeleteDialog, setInsuranceCompanyDeleteDialog] = useState(false);


    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);


    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedAccountNo, setisValidatedAccountNo] = useState(true); 

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    //Get---------
    useEffect(() => {
        getInsuranceCompanyList()
            .then(data => setInsuranceCompanys(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyListGettingError') + ': ' + err.response.data}`, life: 5000 });
            }) 
        setSubmitted(a => !a);
    }, [t]);

    //-------Validation(Check)
    const validForm = () => {
        //debugger;
        let tempNoti = [];
        try {

            //Name
            if (insuranceCompany.name === "" || insuranceCompany.name === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }
            //Code
            if (insuranceCompany.accountNo === "" || insuranceCompany.accountNo === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedAccountNo(false);

            } else {
                setisValidatedAccountNo(true);
            }
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }


    //Save--------
    const save = () => {
        //debugger;
        try {
            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) { 
                
                if (insuranceCompany.id) {

                    updateInsuranceCompanyData();

                }
                else {

                    addInsuranceCompanyData();
                }

                setInsuranceCompanySaveDialog(false);
                setInsuranceCompany(insuranceCompanyModel);
            }

        } catch (error) {
            setInsuranceCompany(insuranceCompanyModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanySaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateInsuranceCompanyData = () => {
        try {

            let _insuranceCompanys = [...insuranceCompanys];
            let _insuranceCompany = { ...insuranceCompany };

            updateInsuranceCompany(insuranceCompany)
                .then(data => {

                    _insuranceCompanys = addToListByIndex(_insuranceCompanys, _insuranceCompany);
                    setInsuranceCompanys(_insuranceCompanys);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('InsuranceCompanyUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setInsuranceCompany(insuranceCompanyModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addInsuranceCompanyData = () => {
        try {

            addInsuranceCompany(insuranceCompany)
                .then(data => {

                    getInsuranceCompanyList()
                        .then(data => setInsuranceCompanys(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('InsuranceCompanyAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setInsuranceCompany(insuranceCompanyModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteInsuranceCompanyData = () => {
        try {

            let _insuranceCompany = { ...insuranceCompany };

            if (insuranceCompanys.some(val => val.id === _insuranceCompany.id)) {
                deleteInsuranceCompany(_insuranceCompany.id)
                    .then(data => {
                        //debugger;
                        // _insuranceCompanys = _insuranceCompanys.filter(val => val.id !== insuranceCompany.id);
                        getInsuranceCompanyList()
                            .then(data => setInsuranceCompanys(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('InsuranceCompanyDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setInsuranceCompany(insuranceCompanyModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setInsuranceCompany(insuranceCompanyModel);
            setInsuranceCompanyDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }





    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < insuranceCompanys.length; i++) {
                if (insuranceCompanys[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setInsuranceCompany(insuranceCompanyModel);
            setSubmitted(false);
            setInsuranceCompanySaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setInsuranceCompany(insuranceCompanyModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editInsuranceCompany = (insuranceCompany) => {
        try {
            debugger;
            let _insuranceCompany = { ...insuranceCompany };
            setSubmitted(false);

            getInsuranceCompanyById(_insuranceCompany.id)
                .then(data => {
                    setInsuranceCompany(data); 
                })
                .catch(err => {
                    //debugger;
                    setInsuranceCompany(insuranceCompanyModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyUpdatedError') + ': ' + err.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setInsuranceCompanySaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanyUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }


    const confirmDeleteInsuranceCompany = (insuranceCompany) => {
        try {

            setInsuranceCompany(insuranceCompany);
            setInsuranceCompanyDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setInsuranceCompany(insuranceCompanyModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setInsuranceCompany(insuranceCompanyModel);
            setSubmitted(false);
            setInsuranceCompanyDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteInsuranceCompany-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setInsuranceCompany(insuranceCompanyModel);
            setSubmitted(false);
            setInsuranceCompanySaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _insuranceCompany = { ...insuranceCompany };
            _insuranceCompany[`${name}`] = val;

            setInsuranceCompany(_insuranceCompany);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**********************



    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteInsuranceCompanyDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteInsuranceCompanyData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('InsuranceCompanyList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateInsuranceCompanyCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editInsuranceCompany(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteInsuranceCompanyCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteInsuranceCompany(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }





    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateInsuranceCompanyCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">



            <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">
                     
                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>
                                <div className="font-medium text-2xl text-900">{t('InsuranceCompany')}</div>
                                <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('InsuranceCompany')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">{t('InsuranceCompanyList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-3 lg:mt-0">

                                <DbCurrencies />

                                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" /> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                        <DataTable ref={dt} value={insuranceCompanys}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="accountNo" header={t('AccountNo')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={insuranceCompanySaveDialog} style={{ width: '550px' }} header={t('InsuranceCompanyForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>
             
                            <div className="field">
                                <label htmlFor="name">{t('Name')} </label>
                                <InputText id="name" value={insuranceCompany.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !insuranceCompany.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="accountNo">{t('AccountNo')} </label>
                                <InputText id="accountNo" value={insuranceCompany.accountNo} onChange={(e) => onInputChange(e, 'accountNo')} required className={classNames({ 'p-invalid': !isValidatedAccountNo })} />
                                {submitted && !insuranceCompany.accountNo && <small className="p-invalid">{t('Required')}</small>}
                            </div> 
                        </Dialog>

                        <Dialog visible={insuranceCompanyDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteInsuranceCompanyDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {insuranceCompany && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>



                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(InsuranceCompany, comparisonFn);

