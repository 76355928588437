import React, { useState, useEffect, useRef } from 'react';

import { groupModel } from '../../components/group/models/group.model';//Model
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { MultiSelect } from 'primereact/multiselect';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getGroupList, getGroupClaims, addGroup, updateGroup, deleteGroup, getGroupById, getOperationClaimsList, saveGroupClaims, getUserList, getGroupUsers, saveGroupUsers } from '../group/services/group.service';




const Group = () => {


    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState(groupModel);
    const [groupClaims, setGroupClaims] = useState({});
    const [groupUsers, setGroupUsers] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const [gruopSaveDialog, setGruopSaveDialog] = useState(false);
    const [gruopClaimsDialog, setGruopClaimsDialog] = useState(false);
    const [gruopUsersDialog, setGruopUsersDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    const [mSelectCalimsValue, setMSelectCalimsValue] = useState(null);
    const [mSelectUsersValue, setMSelectUsersValue] = useState(null);

    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);


    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);




    //Get---------
    useEffect(() => {

        getGroupList()
            .then(data => setGroups(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        setSubmitted(a => !a);

    },[t]);


    //-------Validation(Check)
    const validForm = () => {
        //debugger;
        let tempNoti = [];
        try {

            //Name
            if (group.groupName === "" || group.groupName === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }

    //Save--------
    const save = () => {
        //debugger;
        try {
            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {



                if (group.id) {

                    updateGroupData();

                }
                else {

                    addGroupData();
                }

                setGruopSaveDialog(false);
                setGroup(groupModel);
            }

        } catch (error) {
            setGroup(groupModel);
            
            error.message = error.message + '. UI:SaveOpr.';
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateGroupData = () => {
        try {

            let _groups = [...groups];
            let _group = { ...group };

            updateGroup(group)
                .then(data => {

                    _groups = addToListByIndex(_groups, _group);
                    setGroups(_groups);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('GroupUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setGroup(groupModel);


        } catch (error) {
            
            error.message = error.message + '.UI:UpdateOpr.';
            
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addGroupData = () => {
        try {

            addGroup(group)
                .then(data => {

                    getGroupList()
                        .then(data => setGroups(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('GroupAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setGroup(groupModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteGroupData = () => {
        try {

            let _group = { ...group };

            if (groups.some(val => val.id === _group.id)) {
                deleteGroup(_group.id)
                    .then(data => {
                        //debugger;
                        // _groups = _groups.filter(val => val.id !== group.id);
                        getGroupList()
                            .then(data => setGroups(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('GroupDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setGroup(groupModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setGroup(groupModel);
            setDeleteUserDialog(false);
        } catch (error) {

            
            error.message = error.message + '.UI:DeleteOpr.';
            
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //grub yetki tanımlama işlemleri btn-----
    const saveGroupClaimsData = () => {
        try {
            //debugger;
            let claimsIds = mSelectCalimsValue.map((arr, i) => arr.code);

            saveGroupClaims(group.id, claimsIds)
                .then(data => {
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('GroupClaimsSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    setGroup(groupModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupClaimsError') + ': ' + err.response.data}`, life: 5000 });
                    
                    //throw new Error(`${err.data}`);
                })

            hideClaimsDialog();
        } catch (error) {

            
            error.message = error.message + '.  UI:saveGroupClaimsOpr...';
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupClaimsError') + ': ' + error.message}`, life: 5000 });
            setGroupClaims({});
            setMSelectCalimsValue({});
            setGroup(groupModel);

            setError(error);
        }
    }

    //gruba users atama işlemleri btn-----
    const saveUserClaimsData = () => {
        try {

            let userIds = mSelectUsersValue.map((arr, i) => arr.code);

            saveGroupUsers(group.id, userIds)
                .then(data => {

                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('UserToGroupClaimsSuccess')}`, life: 3000 });

                })
                .catch(err => {

                    setGroup(groupModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('UserToGroupClaimsError') + ': ' + err.response.data}`, life: 5000 });
                    //throw new Error(`${err.data}`);
                })

            hideUsersDialog();
        } catch (error) {
            
            error.message = error.message + '.  UI:saveGroupClaimsOpr...';
            
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('UserToGroupClaimsError') + ': ' + error.message}`, life: 5000 });
            setGroupUsers({});
            setMSelectUsersValue({});
            setGroup(groupModel);

            setError(error);
        }
    }




    //**************************************
    //MultiSelect special fonks. START
    //**************************************

    const selectClaimsFooterTemplate = () => {
        const selectedItems = mSelectCalimsValue;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> {t('Claim')}{length > 1 ? 's' : ''} {t('Selected')}.
            </div>
        );
    }

    const selectUsersFooterTemplate = () => {
        const selectedItems = mSelectUsersValue;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> {t('User')}{length > 1 ? 's' : ''} {t('Selected')}
            </div>
        );
    }

    const selectedItemCalimsTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span className={`mr-2 pi pi-lock-open`} style={{ width: '15px', height: '12px' }} />
                    <span>{option.name}</span>
                </div>
            );
        }

        return '';
    };

    const itemCalimsTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <span className={`mr-2 pi pi-lock`} style={{ width: '18px', height: '12px' }} />
                <span>{option.name}</span>
            </div>
        );
    };

    const selectedItemUsersTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span className={`mr-2 pi pi-user-plus`} style={{ width: '15px', height: '12px' }} />
                    <span>{option.name}</span>
                </div>
            );
        }

        return '';
    };

    const itemUsersTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <span className={`mr-2 pi pi-user-minus`} style={{ width: '18px', height: '12px' }} />
                <span>{option.name}</span>
            </div>
        );
    };

    //**************************************
    //MultiSelect fonks.. END
    //**************************************


    //**************************************
    //Logic fonks. START
    //**************************************



    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < groups.length; i++) {
                if (groups[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);

        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************








    //**************************************
    //btn fonks. START
    //**************************************


    //Excel btn----
    const exportCSV = () => {
        try {

            dt.current.exportCSV();

        } catch (error) {
            error.message = error.message + '. UI:CSV-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setGroup(groupModel);
            setSubmitted(false);
            setGruopSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setGroup(groupModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editeGroup = (group) => {
        try {
            //debugger;
            let _group = { ...group };
            setSubmitted(false);

            getGroupById(_group.id)
                .then(data => {
                    setGroup(data);    
                })
                .catch(err => {
                    //debugger;
                    setGroup(groupModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupUpdatedError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setGruopSaveDialog(true); 
        } catch (error) {
            
            error.message = error.message + '.  UI:EditeModel..';
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //silme onayı btn----
    const confirmDeleteUser = (group) => {
        try {

            setGroup(group);
            setDeleteUserDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setGroup(groupModel);
            setError(error);
        }

    }

    //grup yetkileri getirme btn----
    const getGrupPermissions = (group) => {
        try {
            let tempGroupClaimsArr = [];
            setGroup({ ...group });


            getOperationClaimsList()
                .then(data => {

                    getGroupClaims(group.id)
                        .then(data2 => {
                            // console.log('yetkileri geldi _____ ');
                            //console.log(data2);
                            //debugger;

                            //tüm yetkileri setle

                            setGroupClaims(data.map(function (item, i) {

                                if (data2.length > 0 && data2.some(val => parseInt(val.id) === parseInt(item.id)))
                                    if (item.alias)
                                        tempGroupClaimsArr.push({ name: item.alias, code: parseInt(item.id) });
                                    else
                                        tempGroupClaimsArr.push({ name: item.name, code: parseInt(item.id) });



                                if (item.alias)
                                    return { name: item.alias, code: parseInt(item.id) }
                                else
                                    return { name: item.name, code: parseInt(item.id) }

                            }));

                            //dbdeki yetkileri setle
                            setMSelectCalimsValue(tempGroupClaimsArr);

                        })
                        .catch(err => {
                            setGroupClaims({});
                            setMSelectCalimsValue({});
                            setGroup(groupModel);
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupPermissionsGettingError') + ': ' + err.response.data}`, life: 5000 });
                            //throw new Error(`${err.data}`);
                        })

                })
                .catch(err => {
                    setGroupClaims({});
                    setMSelectCalimsValue({});
                    setGroup(groupModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupPermissionsGettingError') + ': ' + err.response.data}`, life: 5000 });
                   
                    //throw new Error(`${err.data}`);
                })



        } catch (error) {
            error.message = error.message + '.  UI:GrupPermissions-Opr..';
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupPermissionsGettingError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }
    //grup kullanıcıları getirme btn----
    const getGrupUsers = (group) => {
        try {
            let tempGroupUsersArr = [];
            setGroup({ ...group });


            getUserList()
                .then(data => {
                    //console.log(data);

                    getGroupUsers(group.id)
                        .then(data2 => {
                            // console.log('yetkileri geldi _____ ');
                            //console.log(data2);
                            //debugger;

                            //tüm kullanıcıları setle

                            setGroupUsers(data.map(function (item, i) {

                                if (data2.length > 0 && data2.some(val => parseInt(val.id) === parseInt(item.userId)))
                                    tempGroupUsersArr.push({ name: item.fullName, code: parseInt(item.userId) });

                                return { name: item.fullName, code: parseInt(item.userId) }

                            }));

                            //bu gorub için dbdeki kullanıcıları setle
                            setMSelectUsersValue(tempGroupUsersArr);

                        })
                        .catch(err => {
                            getGroupUsers({});
                            setMSelectUsersValue({});
                            setGroup(groupModel);
                            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${err.response.data}`, life: 5000 });
                            //throw new Error(`${err.data}`);
                        })

                })
                .catch(err => {
                    getGroupUsers({});
                    setMSelectUsersValue({});
                    setGroup(groupModel);
                    toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${err.response.data}`, life: 5000 });
                    //throw new Error(`${err.data}`);
                })



        } catch (error) {
            
            error.message = error.message + '.  UI:GrupPermissions-Opr..';
            
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GroupUsersGettingError') + ': ' +  error.message}`, life: 5000 });
            setError(error);
        }
    }


    function editeClaimsClick(group) {
        try {

            setSubmitted(false);

            getGrupPermissions(group);

            setGruopClaimsDialog(true);

        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `' '+${error.message}`, life: 5000 });

            setError(error);
        }


    }

    function editeUsersClick(group) {
        try {

            setSubmitted(false);

            getGrupUsers(group);

            setGruopUsersDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:ByIndexOpr.';

            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });


            setError(error);
        }


    }


    //??Burası user dialog mu olmalı yoksa group dialog mu ?
    //Silme modali kapatma btn-----
    const hideDeleteUserDialog = () => {
        try {
            setGroup(groupModel);
            setSubmitted(false);
            setDeleteUserDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteGroup-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setGroup(groupModel);
            setSubmitted(false);
            setGruopSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //groupo yetki güncelleme modali kapatma btn-----
    const hideClaimsDialog = () => {
        try {
            setGroupClaims({});
            setMSelectCalimsValue(null);
            setSubmitted(false);
            setGruopClaimsDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideClaimsModal..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //groupo kullanıcı güncelleme modali kapatma btn-----
    const hideUsersDialog = () => {
        try {
            setGroupUsers({});
            setMSelectUsersValue(null);
            setSubmitted(false);
            setGruopUsersDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideUsersModal..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _group = { ...group };
            _group[`${name}`] = val;

            setGroup(_group);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary:  `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }



    //**************************************
    //btn fonks. END
    //**************************************



    const userDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideUsersDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={saveUserClaimsData} />
        </>
    );

    const claimsDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideClaimsDialog} />
            <Button label={t('Update')} icon="pi pi-check" className="p-button-text" onClick={saveGroupClaimsData} />
        </>
    );

    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteGroupDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteGroupData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('GroupList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateGroupCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editeGroup(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteGroupCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteUser(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateGroupClaimCommand')) {
                            return (
                                <Button icon="pi pi-lock-open" title={t('GrupPermissions')} className="p-button-rounded mt-2 ml-2 mr-2" onClick={() => editeClaimsClick(rowData)} />

                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserGroupByGroupIdCommand')) {
                            return (
                                <Button icon="pi pi-users" title={t('UsersGroups')} className="p-button-rounded p-button-warning mt-2 ml-2 mr-2" onClick={() => editeUsersClick(rowData)} />
                            )
                        }

                        return null;
                    })()}





                </div>
            </>
        );
    }

    // const verifiedStatusBodyTemplate = (rowData) => {
    //     return <i className={classNames('pi', { 'text-green-500 pi-check-circle': true, 'text-pink-500 pi-times-circle': !true })}></i>;
    // }

    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateGroupCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label={t('ExportData')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }





    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable ref={dt} value={groups}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="groupName" header={t('GroupName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            {/* <Column field="false" header={t('Status')} dataType="boolean" bodyClassName="text-center" style={{ width: '20%', minWidth: '3rem' }} body={verifiedStatusBodyTemplate}></Column> */}
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={gruopSaveDialog} style={{ width: '550px' }} header={t('GroupForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>
                            <div className="field">
                                <label htmlFor="groupName">{t('GroupName')} </label>
                                <InputText id="groupName" value={group.groupName} onChange={(e) => onInputChange(e, 'groupName')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !group.groupName && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteUserDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteGroupDialogFooter} onHide={hideDeleteUserDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {group && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>


                        <Dialog visible={gruopClaimsDialog} style={{ width: '550px' }} header={t('groupClaims')} modal className="p-fluid" footer={claimsDialogFooter} onHide={hideClaimsDialog}>

                            <div className="field">

                                <MultiSelect value={mSelectCalimsValue} onChange={(e) => setMSelectCalimsValue(e.value)} options={groupClaims} optionLabel="name" placeholder={t('slctClaimsGroup')} filter panelFooterTemplate={selectClaimsFooterTemplate} selectedItems={mSelectCalimsValue}
                                    itemTemplate={itemCalimsTemplate} selectedItemTemplate={selectedItemCalimsTemplate} />
                            </div>

                        </Dialog>



                        <Dialog visible={gruopUsersDialog} style={{ width: '550px' }} header={t('GroupUsers')} modal className="p-fluid" footer={userDialogFooter} onHide={hideUsersDialog}>

                            <div className="field">
                                <MultiSelect value={mSelectUsersValue} onChange={(e) => setMSelectUsersValue(e.value)} options={groupUsers} optionLabel="name" placeholder={t('slctUsersGroup')} filter panelFooterTemplate={selectUsersFooterTemplate} selectedItems={mSelectUsersValue}
                                    itemTemplate={itemUsersTemplate} selectedItemTemplate={selectedItemUsersTemplate} />
                            </div>

                        </Dialog>

                    </div>
                </div>
            </div>
        </div>
    );

}




const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Group, comparisonFn);

