import React, { useState, useEffect, useRef } from 'react';

import { languageModel } from '../../components/language/models/language.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getById, getLanguagesList, addLanguage, deleteLanguage, updateLanguage } from '../language/services/language.service';

const Language = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [languages, setLanguages] = useState([]);
    const [language, setLanguage] = useState(languageModel);

    const [submitted, setSubmitted] = useState(false);


    //--------Dialogs
    const [languageSaveDialog, setLanguageSaveDialog] = useState(false);
    const [languageDeleteDialog, setLanguageDeleteDialog] = useState(false);

    //--------Globals
    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedCode, setisValidatedCode] = useState(true);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);

    //Get----------
    useEffect(() => {

        getLanguagesList()
            .then(data => setLanguages(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: `${err}`, life: 5000 });
            })

        setIsSubmitted(a => !a);

    }, []);

    //-------Validation(Check)
    const validForm = () => {

        let tempNoti = [];
        try {

            //Code
            if (language.code === "" || language.code.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('code'), detail: t('Required'), life: 4000, toastId: 'code' });

                setisValidatedCode(false);

            } else {
                setisValidatedCode(true);
            }

            //Name
            if (language.name === "" || language.name.length === 0) {

                tempNoti.push({ severity: 'error', summary: t('value'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }

    //Save------
    const save = () => {
        try {

            //rıdvan'a sorulacak.
            //Bir kayıt insert ediyorum. Ardından insert ettiğim kaydı siliyorum. 
            //Ardından bir boş bir insert gönderdiğimde is submitted true olarak kalıyor.
            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {

                if (language.id) {
                    updateLanguageData();
                }
                else {
                    addLanguageData();
                }

                setLanguageSaveDialog(false);
                setLanguage(languageModel);
            }


        } catch (error) {

            setLanguage(languageModel);

            error.message = error.message + '. UI:SaveOpr.';

            setError(error);
        }
    }

    //Add----
    const addLanguageData = () => {
        try {

            addLanguage(language)
                .then(data => {
                    getLanguagesList()
                        .then(data => setLanguages(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: 'Hata', detail: `${err}`, life: 5000 });
                        })
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Language eklendi.', life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });
                })

            setLanguage(languageModel);

        } catch (error) {

            error.message = error.message + '.UI:AddOpr.';
            setError(error);

        }
    }

    //Update
    const updateLanguageData = () => {
        try {

            let _languages = [...languages];
            let _language = { ...language };

            updateLanguage(language)
                .then(data => {

                    _languages = addToListByIndex(_languages, _language);
                    setLanguages(_languages);
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Language güncellendi.', life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: 'Hata22', detail: `${err}`, life: 5000 });
                    throw new Error(`${err}.Hata özeti: bilgileri getirilemedi`);
                })
            setLanguage(languageModel);

        } catch (error) {

        }


    }

    //Delete----
    const deleteLanguageData = () => {
        try {
            debugger;
            let _language = { ...language };

            if (languages.some(val => val.id === _language.id)) {
                deleteLanguage(_language.id)
                    .then(data => {
                        //debugger;
                        // _groups = _groups.filter(val => val.id !== group.id);
                        getLanguagesList()
                            .then(data => setLanguages(data))
                            .catch(err => {
                                toast.current.show({ severity: 'error', summary: 'Hata', detail: `${err}`, life: 5000 });
                            })
                        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Language Deleted', life: 3000 });
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: err.message, life: 5000 });
                        setLanguage(languageModel);

                        throw new Error(`${err}.Hata özeti: bilgileri getirilemedi`);

                    })
            }

            // setGroups(_groups);
            setLanguage(languageModel);
            setLanguageDeleteDialog(false);

        } catch (error) {

            error.message = error.message + '.UI:DeleteLanguage.';

            setError(error);

        }
    }




    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';

            setError(error);

        }

    }

    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < languages.length; i++) {
                if (languages[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';

            setError(error);

        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************


    //**************************************
    //btn fonks. START
    //**************************************

    //Excel btn----
    const exportCSV = () => {
        try {

            dt.current.exportCSV();

        } catch (error) {
            error.message = error.message + '. UI:CSV-Opr..';

            setError(error);
        }
    }
    //language ekleme btn---
    const openNew = () => {
        try {

            setLanguage(languageModel);
            setSubmitted(false);
            setLanguageSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            setLanguage(languageModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //language güncelleme btn------
    const editLanguage = (language) => {
        try {

            let _language = { ...language };
            setSubmitted(false);

            getById(_language.id)
                .then(data => {
                    setLanguage(data)
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: `${err}`, life: 5000 });
                    setLanguage(languageModel);
                    throw new Error(`${err}.Hata özeti: bilgileri getirilemedi`);
                })

            setLanguageSaveDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:EditeModel..';

            setError(error);
        }
    }

    //language adı silme onayı btn----
    const confirmDeleteLanguage = (language) => {
        try {

            setLanguage(language);
            setLanguageDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';
            setLanguage(languageModel);
            setError(error);
        }

    }

    //Silme modali kapatma btn-----
    const hideDeleteLanguageDialog = () => {
        try {
            setLanguage(languageModel);
            setSubmitted(false);
            setLanguageDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteLanguage-Opr..';

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveLanguageDialog = () => {
        try {

            setLanguage(languageModel);
            setSubmitted(false);
            setLanguageSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';

            setError(error);
        }
    }

    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _language = { ...language };
            _language[`${name}`] = val;

            setLanguage(_language);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**************************************




    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveLanguageDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteLanguageDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteLanguageDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteLanguageData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('LanguagesList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editLanguage(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteLanguage(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateUserCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label={t('ExportData')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable ref={dt} value={languages}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="code" header={t('Code')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={languageSaveDialog} style={{ width: '550px' }} header={t('LanguageForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveLanguageDialog}>
                            <div className="field">
                                <label htmlFor="code">{t('Code')} </label>
                                <InputText id="code" value={language.code} onChange={(e) => onInputChange(e, 'code')} required autoFocus className={classNames({ 'p-invalid': !isValidatedCode })} />
                                {submitted && !language.code && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">{t('Value')} </label>
                                <InputText id="name" value={language.name} onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !language.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>

                        <Dialog visible={languageDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteLanguageDialogFooter} onHide={hideDeleteLanguageDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {language && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );












}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Language, comparisonFn);

