import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { paymentTypesModel } from '../models/paymentTypes.model';//Model




export function getPaymentTypesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/paymentTypes/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getPaymentTypesById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/paymentTypes/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
 