import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
  
export function getMovementTypesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/movementTypes/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getMovementTypesById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/movementTypes/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
 