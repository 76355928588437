
//export const api = 'https://localhost:5001/api/v2';
//  export const api = 'https://213.159.30.35:8555/api/v1';//Canlı
 export const api = 'https://api.tsnethayat.com/api/v1';//Canlı
//  export const api = 'http://213.159.30.35/api/v1';//test

//export const api = 'https://buraktandogan.com/v2/api/v2';//Canlı

export const momentDateFormat = "DD.MM.yyyy HH:mm";

export const approvalList = [
    {
        name:"Hayır",
        id:'0'
    },
    {
        name:"Evet",
        id:'1'
    }
];