import React from 'react';
import { ProgressBar } from 'primereact/progressbar';

const ProgressBarDiv = () => {
 
        return <div className="card">
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
        </div>
   
};

export default ProgressBarDiv