import { api } from '../../../constants/String';
import { HTTP } from "../../../axios/axios";
import { currenciesModel } from '../models/currencies.model';//Model




export function getCurrenciesList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/currencies/getall`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getCurrenciesById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/currencies/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}


export function getCurrenciesByDate(date, currencyTypeId) {
    return new Promise((res, rej) => 
        HTTP.get(`${api}/currencies/getbydate/${date}/${currencyTypeId}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
 
 
export function getCurrenciesGetlast() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/currencies/getlast`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
