import React, { useState, useEffect, useRef } from 'react';

import { subAccountModel } from './models/subAccount.model';
import { productProportionsModel } from '../productProportions/models/productProportions.model';
import { insuranceProductModel } from '../insuranceProduct/models/insuranceProduct.model';
import { agencyOfficersModel } from '../agencyOfficers/models/agencyOfficers.model';

import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
//import { MultiSelect } from 'primereact/multiselect';
import { JwtHelper } from '../../plugins/jwtHelper';
import { TabView, TabPanel } from 'primereact/tabview';
//import { InputNumber } from 'primereact/inputnumber';
import { Panel } from 'primereact/panel';

import { getAccountStatusList, getSubAccountList, addSubAccount, updateSubAccount, deleteSubAccount, getSubAccountById, getSubAccountsByFiltre } from './services/subAccount.service';

import { getMainAccountList } from '../mainAccount/services/mainAccount.service';
import { getCityList } from '../city/services/city.service';
import { getUserList } from '../user/services/user.service';
import { getInsuranceProductList } from '../insuranceProduct/services/insuranceProduct.service';
import { getProductProportionsList, addProductProportions, updateProductProportions, deleteProductProportions, getProductProportionsListBySubAccountId } from '../productProportions/services/productProportions.service';
import { getAgencyOfficersList, addAgencyOfficers, updateAgencyOfficers, deleteAgencyOfficers, getAgencyOfficersListBySubAccountId } from '../agencyOfficers/services/agencyOfficers.service';
import { getCustomerTypeList } from '../customerTypes/services/customerTypes.service';
import { filterModel } from '../subAccount/models/filterModel';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';
import ProgressBarDiv from '../../plugins/progressBar';

const SubAccount = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [subAccounts, setSubAccounts] = useState([]);
    const [subAccount, setSubAccount] = useState(subAccountModel);
    const [submitted, setSubmitted] = useState(false);

    const [mainAccounts, setMainAccounts] = useState([]);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [accountStatus, setAccountStatus] = useState([]);
    const [citys, setCitys] = useState([]);
    const [insuranceProducts, setInsuranceProducts] = useState([]);
    //const [insuranceProduct, setInsuranceProduct] = useState(insuranceProductModel);
    const [filter, setFilter] = useState(filterModel);

    const [productProportions, setProductProportions] = useState([]);
    const [productProportion, setProductProportion] = useState(productProportionsModel);

    const [agencyOfficers, setAgencyOfficers] = useState([]);
    const [agencyOfficer, setAgencyOfficer] = useState(agencyOfficersModel);

    const [users, setUsers] = useState([]);

    const [subAccountSaveDialog, setSubAccountSaveDialog] = useState(false);
    const [subAccountDeleteDialog, setSubAccountDeleteDialog] = useState(false);
    const [productProportionDeleteDialog, setProductProportionDeleteDialog] = useState(false);

    const [agencyOfficerDeleteDialog, setAgencyOfficerDeleteDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    const [customerTypeTcShow, setCustomerTypeTcShow] = useState('hidden field col');
    const [customerTypeTaxShow, setCustomerTypeTaxShow] = useState('hidden field col');
    const [showProgressBar, setShowProgressBar] = useState(false);

    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Multiselect
    const [selectMainAccountsValue, setSelectMainAccountsValue] = useState(null);
    const [selectCustomerTypesValue, setSelectCustomerTypesValue] = useState(null);
    const [selectCitysValue, setSelectCitysValue] = useState(null);
    const [selectInsuranceProductsValue, setSelectInsuranceProductsValue] = useState(null);
    const [selectAccountStatusValue, setSelectAccountStatusValue] = useState(null);
    const [selectUsersValue, setSelectUsersValue] = useState(null);

    //Filter
    const [selectMainAccountsFilter, setSelectMainAccountsFilter] = useState(null);
    const [selectCustomerTypesFilter, setSelectCustomerTypesFilter] = useState(null);
    const [selectCitysFilter, setSelectCitysFilter] = useState(null);
    const [selectAccountStatusFilter, setSelectAccountStatusFilter] = useState(null);

    //----------form data Start
    const [isValidatedSelectMainAccountsValue, setisValidatedSelectMainAccountsValue] = useState(true);
    const [isValidatedSelectCustomerTypesValue, setisValidatedSelectCustomerTypesValue] = useState(true);
    const [isValidatedTaxOrTcNum, setisValidatedTaxOrTcNum] = useState(true);
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedAdress, setisValidatedAdress] = useState(true);
    const [isValidatedDescription, setisValidatedDescription] = useState(true);
    const [isValidatedSelectCitysValue, setisValidatedSelectCitysValue] = useState(true);
    const [isValidatedSelectAccountStatusValue, setisValidatedSelectAccountStatusValue] = useState(true);
    const [isValidatedSelectUsersValue, setisValidatedSelectUsersValue] = useState(true);
    const [isValidatedSelectInsuranceProductsValue, setisValidatedSelectInsuranceProductsValue] = useState(true);


    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);
    //const dtProduct = useRef(null);


    //Get---------
    useEffect(() => {
        // getSubAccountList()
        //     .then(data => setSubAccounts(data))
        //     .catch(err => {
        //         toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountListGettingError') + ': ' + err.response.data}`, life: 5000 });
        //     })

        getMainAccountList()
            .then(data => {
                setMainAccounts(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('MainAccountListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getCustomerTypeList()
            .then(data => {
                setCustomerTypes(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('CustomerTypeListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getCityList()
            .then(data => {
                setCitys(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('cityListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getInsuranceProductList()
            .then(data => {
                setInsuranceProducts(data.map(function (item, i) {
                    return { name: item.productCode + " - " + item.insuranceCompany.name + " - " + item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('insuranceProductsListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })


        getAccountStatusList()
            .then(data => {
                setAccountStatus(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('accaountStatusListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getUserList()
            .then(data => {
                setUsers(data.map(function (item, i) {
                    return { name: item.fullName, code: parseInt(item.userId) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('userListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })


        setSubmitted(a => !a);
    }, [t]);

    //-------Validation(Check)
    const validForm = (btn) => {
        debugger;
        let tempNoti = [];
        try {

            // if (subAccount.customerTypeId === 2) {
            //     subAccount.tc = ""
            // }
            // else if (subAccount.customerTypeId === 1) {
            //     subAccount.taxNo = ""
            // }

            //mainAccount
            if (selectMainAccountsValue === null || selectMainAccountsValue.code === "" || selectMainAccountsValue.code === 0 || selectMainAccountsValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Ana Hesap'), detail: t('Required'), life: 4000, toastId: 'mainAccountId"' });
                setisValidatedSelectMainAccountsValue(false);

            } else {
                subAccount.mainAccountId = selectMainAccountsValue.code;
                subAccount.mainAccount = {
                    id: selectMainAccountsValue.code,
                    name: selectMainAccountsValue.name
                };

                setisValidatedSelectMainAccountsValue(true);
            }

            //customerType
            if (selectCustomerTypesValue === null || selectCustomerTypesValue.code === "" || selectCustomerTypesValue.code === 0 || selectCustomerTypesValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Value'), detail: t('Required'), life: 4000, toastId: 'mainAccountId"' });
                setisValidatedSelectCustomerTypesValue(false);

            } else {
                subAccount.customerTypeId = selectCustomerTypesValue.code;
                subAccount.customerType = {
                    id: selectCustomerTypesValue.code,
                    name: selectCustomerTypesValue.name,
                };

                setisValidatedSelectCustomerTypesValue(true);
            }

            //tax or tc  ValidatedTaxOrTcNum
            debugger;
            if ((subAccount.taxNo === undefined || subAccount.tc === undefined) || (subAccount.tc === "" && subAccount.taxNo === "")) {

                tempNoti.push({ severity: 'error', summary: t('TC Kimlik Veya Vergi No'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedTaxOrTcNum(false);

            } else {
                setisValidatedTaxOrTcNum(true);
            }

            if ((subAccount.name === undefined || subAccount.name === "")) {

                tempNoti.push({ severity: 'error', summary: t('Kart Adı'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            if (selectCitysValue === null || selectCitysValue.code === "" || selectCitysValue.code === 0 || selectCitysValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Lokasyon'), detail: t('Required'), life: 4000, toastId: 'mainAccountId"' });
                setisValidatedSelectCitysValue(false);

            } else {

                subAccount.cityId = selectCitysValue.code;
                subAccount.city = {
                    id: selectCitysValue.code,
                    name: selectCitysValue.name
                };
                setisValidatedSelectCitysValue(true);
            }

            if (selectAccountStatusValue === null || selectAccountStatusValue.code === "" || selectAccountStatusValue.code === 0 || selectAccountStatusValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Hesap Tipi'), detail: t('Required'), life: 4000, toastId: 'mainAccountId"' });
                setisValidatedSelectAccountStatusValue(false);

            } else {

                subAccount.accountStatusId = selectAccountStatusValue.code;
                subAccount.accountStatus = {
                    id: selectAccountStatusValue.code,
                    name: selectAccountStatusValue.name
                };

                setisValidatedSelectAccountStatusValue(true);
            }

            if (selectUsersValue === null || selectUsersValue.code === "" || selectUsersValue.code === 0 || selectUsersValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Kullanıcı'), detail: t('Required'), life: 4000, toastId: 'mainAccountId"' });
                setisValidatedSelectUsersValue(false);

            } else {

                subAccount.userId = selectUsersValue.code;
                subAccount.user = {
                    userId: selectAccountStatusValue.code
                };
                setisValidatedSelectUsersValue(true);
            }

            if ((subAccount.address === undefined || subAccount.address === "")) {

                tempNoti.push({ severity: 'error', summary: t('Adres'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedAdress(false);

            } else {
                setisValidatedAdress(true);
            }
            if ((subAccount.description === undefined || subAccount.description === "")) {

                tempNoti.push({ severity: 'error', summary: t('Açıklama'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedDescription(false);

            } else {
                setisValidatedDescription(true);
            }


            if (btn === "saveProductProportions") {
                if (selectInsuranceProductsValue === null || selectInsuranceProductsValue.code === "" || selectInsuranceProductsValue.code === 0 || selectInsuranceProductsValue.code === undefined) {

                    tempNoti.push({ severity: 'error', summary: t('Ürün'), detail: t('Required'), life: 4000, toastId: 'mainAccountId"' });
                    setisValidatedSelectInsuranceProductsValue(false);

                } else {

                    productProportion.insuranceProductId = selectInsuranceProductsValue.code;
                    productProportion.subAccountId = subAccount.id;

                    setisValidatedSelectInsuranceProductsValue(true);
                }
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }

    //Save--------
    const save = () => {
        //debugger;
        try {

            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {

                if (subAccount.id) {

                    updateSubAccountData();

                }
                else {

                    addSubAccountData();
                }

                setSubAccountSaveDialog(false);
                setSubAccount(subAccountModel);
            }

        } catch (error) {
            setSubAccount(subAccountModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Save-------- ProductProportions modeli oluşturlarak kayıt gerçekleştirilecek.
    const saveProductProportions = () => {
        debugger;
        try {

            let validStatus = validForm("saveProductProportions");
            setIsSubmitted(validStatus);

            if (validStatus) {
                if (subAccount.id) {
                    // currencyTypeId 1 TL 2 USD 3 EUR
                    if (productProportion.tl !== "") {
                        productProportion.currencyTypeId = 1;
                        productProportion.amount = productProportion.tl;
                        addProductProportionData();
                    }
                    if (productProportion.usd !== "") {
                        productProportion.currencyTypeId = 2;
                        productProportion.amount = productProportion.usd;
                        addProductProportionData();
                    }
                    if (productProportion.eu !== "") {
                        productProportion.currencyTypeId = 3;
                        productProportion.amount = productProportion.eu;
                        addProductProportionData();
                    }

                }
                else {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('Acenta oluşturulmadan ürün tanımlanamaz.') + ': ' + error.message}`, life: 5000 });
                }
            }

            setProductProportion(productProportionsModel);


        } catch (error) {
            setProductProportion(productProportionsModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Add----ProductProportions
    const addProductProportionData = () => {
        try {

            addProductProportions(productProportion)
                .then(data => {

                    getProductProportionsListBySubAccountId(subAccount.id)
                        .then(data => setProductProportions(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('SubAccountAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + err.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setProductProportion(productProportionsModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //Update----
    const updateProductProportionData = () => {
        try {
            debugger;
            let _productProportions = [...productProportions];
            let _productProportion = { ...productProportion };

            updateProductProportions(productProportion)
                .then(data => {

                    _productProportions = addProductProportionToListByIndex(_productProportions, _productProportion);
                    setProductProportions(_productProportions);


                    toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('productProportionUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('productProportionUpdateError') + ': ' + err.response.data}`, life: 5000 });
                })

            //setProductProportion(productProportionsModel);
            // setProductProportions([]);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('ProductProportionUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Save-------- ProductProportions modeli oluşturlarak kayıt gerçekleştirilecek.
    const saveAgencyOfficers = () => {
        //debugger;
        try {

            agencyOfficer.subAccountId = subAccount.id;

            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (subAccount.id) {
                addAgencyOfficersData();
            }
            else {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('Acenta oluşturulmadan acente yetkilisi tanımlanamaz.') + ': ' + error.message}`, life: 5000 });
            }


            setAgencyOfficer(agencyOfficersModel);


        } catch (error) {
            setAgencyOfficer(agencyOfficersModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficerSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //AgencyOfficers
    const addAgencyOfficersData = () => {
        try {

            addAgencyOfficers(agencyOfficer)
                .then(data => {

                    getAgencyOfficersListBySubAccountId(subAccount.id)
                        .then(data => setAgencyOfficers(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficersAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('Error')}`, detail: `${t('AgencyOfficersAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficersAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setAgencyOfficer(agencyOfficersModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //Update----
    const updateAgencyOfficersData = () => {
        try {
            debugger;
            let _agencyOfficers = [...agencyOfficers];
            let _agencyOfficer = { ...agencyOfficer };

            updateAgencyOfficers(agencyOfficer)
                .then(data => {
                    //debugger;
                    _agencyOfficers = addAgencyOfficersToListByIndex(_agencyOfficers, _agencyOfficer);
                    setAgencyOfficers(_agencyOfficers);

                    toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('AgencyOfficerUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficerUpdateError') + ': ' + err.response.data}`, life: 5000 });
                })

        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficerUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Update----
    const updateSubAccountData = () => {
        try {
            debugger;
            let _subAccounts = [...subAccounts];
            let _subAccount = { ...subAccount };

            updateSubAccount(subAccount)
                .then(data => {

                    _subAccounts = addToListByIndex(_subAccounts, _subAccount);
                    setSubAccounts(_subAccounts);
                    toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('SubAccountUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setSubAccount(subAccountModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addSubAccountData = () => {
        try {
            debugger;
            addSubAccount(subAccount)
                .then(data => {

                    getSubAccountList()
                        .then(data => setSubAccounts(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('Error')}`, detail: `${t('SubAccountAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setSubAccount(subAccountModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //silme onayı btn----
    const deleteSubAccountData = () => {
        try {

            let _subAccount = { ...subAccount };

            if (subAccounts.some(val => val.id === _subAccount.id)) {
                deleteSubAccount(_subAccount.id)
                    .then(data => {
                        //debugger;
                        // _subAccounts = _subAccounts.filter(val => val.id !== subAccount.id);
                        getSubAccountList()
                            .then(data => setSubAccounts(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('SubAccountDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('SubAccountDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setSubAccount(subAccountModel);
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setSubAccount(subAccountModel);
            setSubAccountDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    const deleteProductProportionData = () => {
        //debugger;
        try {

            let _productProportion = { ...productProportion };

            if (productProportions.some(val => val.id === _productProportion.id)) {
                deleteProductProportions(_productProportion.id)
                    .then(data => {
                        //debugger;
                        // _productProportions = _productProportions.filter(val => val.id !== productProportion.id);
                        getProductProportionsListBySubAccountId(_productProportion.subAccountId)
                            .then(data => setProductProportions(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('ProductProportionDeleteError') + ': ' + err.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('ProductProportionDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setProductProportion(productProportionsModel);
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('ProductProportionsDeleteError') + ': ' + err.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setProductProportion(productProportionsModel);
            setProductProportionDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('ProductProportionDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    const deleteAgencyOfficersData = () => {
        //debugger;
        try {

            let _agencyOfficer = { ...agencyOfficer };

            if (agencyOfficers.some(val => val.id === _agencyOfficer.id)) {
                deleteAgencyOfficers(_agencyOfficer.id)
                    .then(data => {
                        getAgencyOfficersListBySubAccountId(_agencyOfficer.subAccountId)
                            .then(data => setAgencyOfficers(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficerDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('Success')}`, detail: `${t('AgencyOfficerDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setAgencyOfficer(agencyOfficersModel);
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficersDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setAgencyOfficer(agencyOfficersModel);
            setAgencyOfficerDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficerDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }
    const toggleProgressBar = (showProgressBar) => {
        setShowProgressBar(showProgressBar);
    };

    const getListByFiltre = () => {
        debugger;
        try {

            if (selectCitysFilter == null || selectCitysFilter === undefined) {
                filter.cityId = 0;
            }
            else {
                filter.cityId = selectCitysFilter.code;
            }
            if (selectAccountStatusFilter == null || selectAccountStatusFilter === undefined) {
                filter.accountStatusId = 0;
            }
            else {
                filter.accountStatusId = selectAccountStatusFilter.code;
            }
            if (selectCustomerTypesFilter == null || selectCustomerTypesFilter === undefined) {
                filter.customerTypeId = 0;
            }
            else {
                filter.customerTypeId = selectCustomerTypesFilter.code;
            }
            if (selectMainAccountsFilter == null || selectMainAccountsFilter === undefined) {
                filter.mainAccountId = 0;
            }
            else {
                filter.mainAccountId = selectMainAccountsFilter.code;
            }
            toggleProgressBar(true);
            getSubAccountsByFiltre(filter)
                .then(data => { setSubAccounts(data.data); toggleProgressBar(false); })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('FiltreListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    toggleProgressBar(false);
                })


        } catch (error) {
            setSubAccounts(subAccountModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
            toggleProgressBar(false);
        }

    }


    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {
        //debugger;
        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }

    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < subAccounts.length; i++) {
                if (subAccounts[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    const addProductProportionToListByIndex = (list, item) => {
        //debugger;
        try {
            if (findProductProportionIndexById(item.id) >= 0) {

                const index = findProductProportionIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }

    const findProductProportionIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < productProportions.length; i++) {
                if (productProportions[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    const addAgencyOfficersToListByIndex = (list, item) => {
        //debugger;
        try {
            if (findAgencyOfficersIndexById(item.id) >= 0) {

                const index = findAgencyOfficersIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }

    const findAgencyOfficersIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < agencyOfficers.length; i++) {
                if (agencyOfficers[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setSubAccount(subAccountModel);
            setSubmitted(false);
            setSubAccountSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setSubAccount(subAccountModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editSubAccount = (subAccount) => {
        try {
            //debugger;
            let _subAccount = { ...subAccount };
            setSubmitted(false);

            getSubAccountById(_subAccount.id)
                .then(data => {
                    for (let index = 0; index < mainAccounts.length; index++) {
                        const element = mainAccounts[index];

                        if (element.code === data.mainAccountId) {
                            setSelectMainAccountsValue({ name: element.name, code: parseInt(element.code) });
                        }
                    }
                    for (let index = 0; index < citys.length; index++) {
                        const element = citys[index];

                        if (element.code === data.cityId) {
                            setSelectCitysValue({ name: element.name, code: parseInt(element.code) });
                        }

                    }
                    for (let index = 0; index < accountStatus.length; index++) {
                        const element = accountStatus[index];

                        if (element.code === data.accountStatusId) {
                            setSelectAccountStatusValue({ name: element.name, code: parseInt(element.code) });
                        }

                    }
                    for (let index = 0; index < users.length; index++) {
                        const element = users[index];

                        if (element.code === data.userId) {
                            setSelectUsersValue({ name: element.name, code: parseInt(element.code) });
                        }
                    }

                    for (let index = 0; index < customerTypes.length; index++) {
                        const element = customerTypes[index];

                        if (element.code === data.customerTypeId) {
                            setSelectCustomerTypesValue({ name: element.name, code: parseInt(element.code) });
                        }

                    }


                    if (data.customerTypeId === 1) {
                        setCustomerTypeTcShow("show field col");
                        setCustomerTypeTaxShow("hidden field col");
                    } else if (data.customerTypeId === 2) {
                        setCustomerTypeTaxShow("show field col");
                        setCustomerTypeTcShow("hidden field col");
                    }

                    setSubAccount(data);
                })
                .catch(err => {
                    //debugger;
                    setSubAccount(subAccountModel);
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('GetSubAccountByIdError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setSubAccountSaveDialog(true);

            getProductProportionsListBySubAccountId(_subAccount.id)
                .then(data => setProductProportions(data))
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('ProductProportionsListBySubAccountIdGettingError') + ': ' + err.response.data}`, life: 5000 });
                })

            getAgencyOfficersListBySubAccountId(_subAccount.id)
                .then(data => setAgencyOfficers(data))
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('AgencyOfficersListBySubAccountIdGettingError') + ': ' + err.response.data}`, life: 5000 });
                })


        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //ProductProportion güncelleme btn------
    // const editProductProportions = (productProportion) => {
    //         try {
    //             debugger;
    //             let _productProportion = { ...productProportion };
    //             setSubmitted(false);

    //             getSubAccountById(_productProportion.id)
    //                 .then(data => {
    //                     for (let index = 0; index < mainAccounts.length; index++) {
    //                         const element = mainAccounts[index];

    //                         if (element.code === data.mainAccountId) {
    //                             setSelectMainAccountsValue({ name: element.name, code: parseInt(element.code) });
    //                         }
    //                     }

    //                     setSubAccount(data);
    //                 })
    //                 .catch(err => {
    //                     //debugger;
    //                     setSubAccount(subAccountModel);
    //                     toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('SubAccountUpdatedError') + ': ' + err.data}`, life: 5000 });

    //                     //throw new Error(`${err.message}`);
    //                 })

    //             setSubAccountSaveDialog(true);
    //         } catch (error) {

    //             error.message = error.message + '.  UI:EditeModel..';

    //             toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('SubAccountUpdatedError') + ': ' + error.message}`, life: 5000 });

    //             setError(error);
    //         }
    // }

    const confirmDeleteSubAccount = (subAccount) => {
        try {

            setSubAccount(subAccount);
            setSubAccountDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setSubAccount(subAccountModel);
            setError(error);
        }

    }

    const confirmDeleteProductProportion = (productProportion) => {
        try {

            setProductProportion(productProportion);
            setProductProportionDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setProductProportion(productProportionsModel);
            setError(error);
        }

    }

    const confirmDeleteAgencyOfficers = (agencyOfficer) => {
        try {

            setAgencyOfficer(agencyOfficer);
            setAgencyOfficerDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setAgencyOfficer(agencyOfficersModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setSubAccount(subAccountModel);
            setSubmitted(false);
            setSubAccountDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteSubAccount-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    const hideProductProportionDeleteDialog = () => {
        try {
            setProductProportion(productProportionsModel);
            setSubmitted(false);
            setProductProportionDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteSubAccount-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const hideAgencyOfficersDeleteDialog = () => {
        try {
            setAgencyOfficer(agencyOfficersModel);
            setSubmitted(false);
            setAgencyOfficerDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteSubAccount-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setSubAccount(subAccountModel);
            setSubmitted(false);
            setSubAccountSaveDialog(false);
            setProductProportions([]);
            setAgencyOfficers([]);
            clearProductProportion();
            clearAgencyOfficer();

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _subAccount = { ...subAccount };
            _subAccount[`${name}`] = val;

            setSubAccount(_subAccount);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    const onInputFilterChange = (e, name) => {
        try {
            const val = (e.target && e.target.value) || '';
            let _filter = { ...filter };
            _filter[`${name}`] = val;

            setFilter(_filter);

        } catch (error) {
            error.message = error.message + '.  UI:InputFilterChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**------
    const onInputChangeProductProportions = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _productProportion = { ...productProportion };
            _productProportion[`${name}`] = val;

            setProductProportion(_productProportion);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onInputChangeAgencyOfficer = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _agencyOfficer = { ...agencyOfficer };
            _agencyOfficer[`${name}`] = val;

            setAgencyOfficer(_agencyOfficer);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    const onCustomerTypeChange = (e, name) => {
        try {

            //debugger;

            const val = (e.target && e.target.value) || '';
            let _subAccount = { ...subAccount };
            _subAccount[`${customerTypes}`] = val;

            setCustomerTypeTcShow('hidden field col');
            setCustomerTypeTaxShow('hidden field col');

            //1 gerçek 2 tüzel
            if (val.code === 1) {
                setCustomerTypeTcShow('show field col');
                _subAccount[`${'taxNo'}`] = "";
            }
            else if (val.code === 2) {
                setCustomerTypeTaxShow('show field col');
                _subAccount[`${'tc'}`] = "";
            }


            setSelectCustomerTypesValue(e.value);
            setSubAccount(_subAccount);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onRowEditComplete1 = (e) => {
        //debugger;
        productProportion.id = e.newData.id;
        productProportion.currencyTypeId = e.newData.currencyTypeId;
        productProportion.amount = e.newData.amount;
        productProportion.subAccountId = e.newData.subAccountId;
        productProportion.insuranceProductId = e.newData.insuranceProductId;
        productProportion.currencyType.name = e.newData.currencyType.name;

        updateProductProportionData();

        clearProductProportion();


    }

    const clearProductProportion = () => {

        productProportion.id = 0;
        productProportion.tl = 0;
        productProportion.usd = 0;
        productProportion.eu = 0;
        productProportion.amount = 0;
        productProportion.currencyTypeId = 0;
        productProportion.subAccountId = 0;
        productProportion.insuranceProductId = 0;

    }

    const clearAgencyOfficer = () => {

        agencyOfficer.id = 0;
        agencyOfficer.companyId = 0;
        agencyOfficer.subAccountId = 0;
        agencyOfficer.firstName = '';
        agencyOfficer.lastName = '';
        agencyOfficer.title = '';
        agencyOfficer.email = '';
        agencyOfficer.phoneNumber = '';

    }

    const onRowEditComplete2 = (e) => {
        //debugger;
        agencyOfficer.id = e.newData.id;
        agencyOfficer.companyId = e.newData.companyId;
        agencyOfficer.subAccountId = e.newData.subAccountId;
        agencyOfficer.firstName = e.newData.firstName;
        agencyOfficer.lastName = e.newData.lastName;
        agencyOfficer.title = e.newData.title;
        agencyOfficer.email = e.newData.email;
        agencyOfficer.phoneNumber = e.newData.phoneNumber;

        updateAgencyOfficersData();

        clearAgencyOfficer();


    }


    // const onRowEditComplete1 = (e) => {
    //     debugger;
    //     let _productProportion = { ...productProportion }; 
    //     let { newData, index } = e;
    //     _productProportion[index] = newData;
    //     setProductProportions(_productProportion);
    // }



    //**************************************
    //btn fonks. END
    //**********************



    // const saveDialogFooter = (
    //     <>
    //         <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
    //         <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
    //     </>
    // );


    const deleteSubAccountDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteSubAccountData} />
        </>
    );

    const deleteProductProportionDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideProductProportionDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteProductProportionData} />
        </>
    );

    const deleteAgencyOfficersDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideAgencyOfficersDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteAgencyOfficersData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('SubAccountList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateSubAccountCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editSubAccount(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const actionBodyTemplate2 = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteSubAccountCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteSubAccount(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const actionBodyProductProportionsTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={() => confirmDeleteProductProportion(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const actionBodyAgencyOfficersTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={() => confirmDeleteAgencyOfficers(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }



    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateSubAccountCommand')) {
            return (

                <React.Fragment>
                    <Button label={t('Filtrele')} icon="pi pi-filter" className="p-button-warning mr-2" onClick={getListByFiltre} />


                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}

                </React.Fragment>
            )
        }
    }

    const centerToolbarFiltring = () => {
        return (
            <>
                {(() => {

                    return (

                        <div className="my-2 " >
                            <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />

                            <hr></hr>

                            <Dropdown showClear filter className="col-md-4 mr-2 mb-1" id="slcMainAccountId" placeholder="Ana Hesap Seçin" value={selectMainAccountsFilter} options={mainAccounts} onChange={(e) => setSelectMainAccountsFilter(e.value)} optionLabel="name" />

                            <Dropdown showClear filter className="col-md-4 mr-2 mb-1" id="slcAccountStatus" placeholder="Kart Durumu Seçin" value={selectAccountStatusFilter} options={accountStatus} onChange={(e) => setSelectAccountStatusFilter(e.value)} optionLabel="name" />

                            <Dropdown showClear filter className="col-md-4 mr-2 mb-1" id="slcCustomerType" placeholder="Mşteri Tipi Seçin" value={selectCustomerTypesFilter} options={customerTypes} onChange={(e) => setSelectCustomerTypesFilter(e.value)} optionLabel="name" />

                            <Dropdown showClear filter className="col-md-4 mr-2 mb-1" id="slcCity" placeholder="Şehir Seçin" value={selectCitysFilter} options={citys} onChange={(e) => setSelectCitysFilter(e.value)} optionLabel="name" />

                            <InputText className="col-md-4 mr-2 mb-1" id="filterSearchKey" placeholder="Aranacak Kelime" value={filter.search} onChange={(e) => onInputFilterChange(e, 'searchText')} />


                        </div>

                    )

                })()}
            </>
        );

    }


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }

    /////////
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const statusBodyTemplate = (rowData) => {
        return getStatusLabel(rowData.insuranceProductId);
    }

    const getStatusLabel = (status) => {

        let name = 'Bulunamadı';
        insuranceProducts.map(function (item, i) {
            if (parseInt(item.code) === parseInt(status)) {
                name = item.name;
            }
        });
        return (<span>{name}</span>);
    }


    const statusEditor = (options) => {
        //debugger;

        return (

            <Dropdown value={options.value} options={insuranceProducts} optionLabel="name" optionValue="code"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }} />

        );
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>

                                <ul className="list-none p-0 m-0 flex align-items-center font-medium">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">KARTLAR</span>
                                    </li>
                                    <li className="px-2">
                                        |
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Kartlar</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">Kartlar Listesi</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-2">

                                <DbCurrencies />

                            </div>
                        </div>
                    </div>
                </div>



                <div>
                    <div className="card">

                        <Toast ref={toast} />
                        <Panel header={leftToolbarTemplate} toggleable collapsed='true' collapseIcon="pi pi-angle-up" expandIcon="pi pi-angle-down">
                            <div>{centerToolbarFiltring()}</div>
                        </Panel>
                        {/* <Toolbar className="mb-4" left={centerToolbarFiltring} ></Toolbar> */}
                        {showProgressBar && <ProgressBarDiv />}
                        <DataTable ref={dt} value={subAccounts}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="customerType.name" header={t('CustomerType')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('AgencyName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="taxNo" header={t('TaxNo')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="tc" header={t('TCIdentificationNumber')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="description" header={t('Description')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="user.fullName" header={t('Representative')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="city.name" header={t('City')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>

                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column header="#" body={actionBodyTemplate2} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={subAccountSaveDialog} style={{ width: '700px' }} header={t('SubAccountForm')} modal className="p-fluid" onHide={hideSaveDialog}>

                            <TabView>
                                <TabPanel header="Kart Bilgileri">
                                    <br></br>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="mainAccountId">{t('MainAccount')} </label>
                                            <Dropdown id="mainAccountId" value={selectMainAccountsValue} options={mainAccounts} onChange={(e) => setSelectMainAccountsValue(e.value)} optionLabel="name" placeholder="Ana Hesap Seçin" className={classNames({ 'p-invalid': !isValidatedSelectMainAccountsValue })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="customerTypeId">{t('CustomerType')} </label>
                                            <Dropdown id="customerTypeId" value={selectCustomerTypesValue} options={customerTypes} onChange={(e) => onCustomerTypeChange(e)} optionLabel="name" placeholder="Hesap Tipi Seçin" className={classNames({ 'p-invalid': !isValidatedSelectCustomerTypesValue })} />
                                        </div>

                                    </div>
                                    <div className="formgrid grid">
                                        <div className={customerTypeTcShow} >
                                            <label htmlFor="tc">{t('TCIdentificationNumber')} </label>
                                            <InputText id="tc" value={subAccount.tc} onChange={(e) => onInputChange(e, 'tc')} required autoFocus className={classNames({ 'p-invalid': !isValidatedTaxOrTcNum })} />
                                            {submitted && !subAccount.tc && <small className="p-invalid">{t('Required')}</small>}
                                        </div>
                                        {/* visible={customerTypeShow}  */}
                                        <div className={customerTypeTaxShow}  >
                                            <label htmlFor="taxNo">{t('TaxNo')} </label>
                                            <InputText id="taxNo" value={subAccount.taxNo} onChange={(e) => onInputChange(e, 'taxNo')} required autoFocus className={classNames({ 'p-invalid': !isValidatedTaxOrTcNum })} />
                                            {submitted && !subAccount.taxNo && <small className="p-invalid">{t('Required')}</small>}
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="name">{t('Name')} </label>
                                            <InputText id="name" value={subAccount.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                            {submitted && !subAccount.name && <small className="p-invalid">{t('Required')}</small>}
                                        </div>
                                    </div>

                                    <div className="formgrid grid">

                                        <div className="field col">
                                            <label htmlFor="cityId">{t('City')} </label>
                                            <Dropdown id="cityId" value={selectCitysValue} options={citys} onChange={(e) => setSelectCitysValue(e.value)} optionLabel="name" placeholder="Lokasyon Seçiniz" className={classNames({ 'p-invalid': !isValidatedSelectCitysValue })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="accountStatusId">{t('Status')} </label>
                                            <Dropdown id="accountStatusId" value={selectAccountStatusValue} options={accountStatus} onChange={(e) => setSelectAccountStatusValue(e.value)} optionLabel="name" placeholder="Hesap Durumu Seçiniz" className={classNames({ 'p-invalid': !isValidatedSelectAccountStatusValue })} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="userId">{t('User')} </label>
                                        <Dropdown id="userId" value={selectUsersValue} options={users} onChange={(e) => setSelectUsersValue(e.value)} optionLabel="name" placeholder="Temsilci Seçiniz" className={classNames({ 'p-invalid': !isValidatedSelectUsersValue })} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="description">{t('Description')} </label>
                                        <InputText id="description" value={subAccount.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': !isValidatedDescription })}/>
                                        {submitted && !subAccount.description && <small className="p-invalid">{t('Required')}</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="address">{t('Address')} </label>
                                        <InputText id="address" value={subAccount.address} onChange={(e) => onInputChange(e, 'address')} required autoFocus  className={classNames({ 'p-invalid': !isValidatedAdress })}/>
                                        {submitted && !subAccount.address && <small className="p-invalid">{t('Required')}</small>}
                                    </div>
                                    <div className="p-dialog-footer">
                                        <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
                                        <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
                                    </div>

                                </TabPanel>
                                <TabPanel header="Komisyon Bilgileri" >

                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="insuranceProductId">{t('InsuranceProduct')} </label>
                                            <Dropdown id="insuranceProductId" value={selectInsuranceProductsValue} options={insuranceProducts} onChange={(e) => setSelectInsuranceProductsValue(e.value)} optionLabel="name" placeholder="Hesap Tipi Seçin" className={classNames({ 'p-invalid': !isValidatedSelectInsuranceProductsValue })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="tl">{t('Tl')} </label>
                                            <InputText id="tl" value={productProportion.tl} onChange={(e) => onInputChangeProductProportions(e, 'tl')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="usd">{t('Usd')} </label>
                                            <InputText id="usd" value={productProportion.usd} onChange={(e) => onInputChangeProductProportions(e, 'usd')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="eu">{t('Eur')} </label>
                                            <InputText id="eu" value={productProportion.eu} onChange={(e) => onInputChangeProductProportions(e, 'eu')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor=""># </label>
                                            <Button label={t('Ekle')} icon="pi pi-check" className="p-button-text" onClick={saveProductProportions} />
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <DataTable value={productProportions} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete1} responsiveLayout="scroll">
                                        <Column field="insuranceProductId" header={t('InsuranceProductName')} body={statusBodyTemplate} editor={(options) => statusEditor(options)} style={{ width: '30%' }}></Column>
                                        <Column field="currencyType.name" header={t('CurrencyType')} style={{ width: '20%' }}></Column>
                                        <Column field="amount" header={t('Amount')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                                        <Column rowEditor bodyStyle={{ textAlign: 'center' }} style={{ width: '10%' }}></Column>
                                        <Column header="#" body={actionBodyProductProportionsTemplate} style={{ width: '10%' }}></Column>
                                    </DataTable>
                                </TabPanel>
                                <TabPanel header="Yetkili Tanımlama">
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="title">{t('Title')} </label>
                                            <InputText id="title" value={agencyOfficer.title} onChange={(e) => onInputChangeAgencyOfficer(e, 'title')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="firstName">{t('FirstName')} </label>
                                            <InputText id="firstName" value={agencyOfficer.firstName} onChange={(e) => onInputChangeAgencyOfficer(e, 'firstName')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="lastName">{t('LastName')} </label>
                                            <InputText id="lastName" value={agencyOfficer.lastName} onChange={(e) => onInputChangeAgencyOfficer(e, 'lastName')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="email">{t('Email')} </label>
                                            <InputText id="email" value={agencyOfficer.email} onChange={(e) => onInputChangeAgencyOfficer(e, 'email')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="phoneNumber">{t('MobilePhones')} </label>
                                            <InputText id="phoneNumber" value={agencyOfficer.phoneNumber} onChange={(e) => onInputChangeAgencyOfficer(e, 'phoneNumber')} required className={classNames({ 'p-invalid': !isValidatedName })} />
                                        </div>
                                        <div className="field col">
                                            <label htmlFor="#">{t('#')} </label>
                                            <Button label={t('Ekle')} icon="pi pi-check" className="p-button-text" onClick={saveAgencyOfficers} />
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <DataTable value={agencyOfficers} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete2} responsiveLayout="scroll">
                                        <Column field="title" header={t('Title')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                                        <Column field="firstName" header={t('FirstName')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                                        <Column field="lastName" header={t('LastName')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                                        <Column field="email" header={t('Email')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                                        <Column field="phoneNumber" header={t('MobilePhones')} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                                        <Column rowEditor bodyStyle={{ textAlign: 'center' }} style={{ width: '10%' }}></Column>
                                        <Column header="#" body={actionBodyAgencyOfficersTemplate} style={{ width: '10%' }}></Column>
                                    </DataTable>
                                </TabPanel>
                            </TabView>
                        </Dialog>

                        <Dialog visible={subAccountDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteSubAccountDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {subAccount && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={productProportionDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteProductProportionDialogFooter} onHide={hideProductProportionDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {subAccount && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={agencyOfficerDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteAgencyOfficersDialogFooter} onHide={hideAgencyOfficersDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {subAccount && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SubAccount, comparisonFn);

