import React, { useState, useEffect, useRef } from 'react';

import { insuranceProductModel } from './models/insuranceProduct.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { JwtHelper } from '../../plugins/jwtHelper';

import { getInsuranceProductList, addInsuranceProduct, updateInsuranceProduct, deleteInsuranceProduct, getInsuranceProductById } from '../insuranceProduct/services/insuranceProduct.service';
import { getInsuranceCompanyList } from '../insuranceCompany/services/insuranceCompany.service';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';

const InsuranceProduct = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [insuranceProducts, setInsuranceProducts] = useState([]);
    const [insuranceProduct, setInsuranceProduct] = useState(insuranceProductModel);
    const [submitted, setSubmitted] = useState(false);
    const [insuranceCompanys, setInsuranceCompanys] = useState([]);
    const [insuranceProductSaveDialog, setInsuranceProductSaveDialog] = useState(false);
    const [insuranceProductDeleteDialog, setInsuranceProductDeleteDialog] = useState(false);


    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);


    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Multiselect
    const [selectInsuranceCompanysValue, setSelectInsuranceCompanysValue] = useState(null);
    const [selectYesNoValue, setSelectYesNoValue] = useState(null);
    const selectYesNoValues = [
        { name: 'Evet', code: true },
        { name: 'Hayır', code: false }
    ];

    //----------form data Start
    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedProductCode, setisValidatedProductCode] = useState(true);
    const [isValidatedInsuranceCompanyId, setisValidatedInsuranceCompanyId] = useState(true);
    const [isValidatedElementer, setisValidatedElementer] = useState(true);



    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);


    //Get---------
    useEffect(() => {
        getInsuranceProductList()
            .then(data => setInsuranceProducts(data))
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getInsuranceCompanyList()
            .then(data => {
                setInsuranceCompanys(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceCompanysListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        setSubmitted(a => !a);
    }, [t]);



    //-------Validation(Check)
    const validForm = () => {
        debugger;
        let tempNoti = [];
        try {



            //InsuranceCompanyId
            if (selectInsuranceCompanysValue === null || selectInsuranceCompanysValue.code === "" || selectInsuranceCompanysValue.code === 0) {

                tempNoti.push({ severity: 'error', summary: t('Sigorta Şirketi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedInsuranceCompanyId(false);

            } else {

                insuranceProduct.insuranceCompany = {
                    id: selectInsuranceCompanysValue.code,
                    name: selectInsuranceCompanysValue.name
                };
                insuranceProduct.insuranceCompanyId = selectInsuranceCompanysValue.code;
                setisValidatedInsuranceCompanyId(true);
            }


            //Name
            if (insuranceProduct.name === "" || insuranceProduct.name === 0) {

                tempNoti.push({ severity: 'error', summary: t('Ürün Adı'), detail: t('Required'), life: 4000, toastId: 'name"' });

                setisValidatedName(false);

            } else {
                setisValidatedName(true);
            }

            //elementer
            if (selectYesNoValue === null || selectYesNoValue.code === "" || selectYesNoValue.code === 0) {

                tempNoti.push({ severity: 'error', summary: t('Elementer'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedElementer(false);

            } else {
                insuranceProduct.elementer = selectYesNoValue.code;

                setisValidatedElementer(true);
            }
            
            //ProductCode
            if (insuranceProduct.productCode === "" || insuranceProduct.productCode === 0) {

                tempNoti.push({ severity: 'error', summary: t('Ürün Kodu'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedProductCode(false);

            } else {
                setisValidatedProductCode(true);
            }




            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }


    //Save--------
    const save = () => {
        //debugger;
        try {



            let validStatus = validForm();
            setIsSubmitted(validStatus);

            if (validStatus) {

                if (insuranceProduct.id) {

                    updateInsuranceProductData();

                }
                else {

                    addInsuranceProductData();
                }

                setInsuranceProductSaveDialog(false);
                setInsuranceProduct(insuranceProductModel);
            }

        } catch (error) {
            setInsuranceProduct(insuranceProductModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateInsuranceProductData = () => {
        try {

            let _insuranceProducts = [...insuranceProducts];
            let _insuranceProduct = { ...insuranceProduct };

            updateInsuranceProduct(insuranceProduct)
                .then(data => {

                    _insuranceProducts = addToListByIndex(_insuranceProducts, _insuranceProduct);
                    setInsuranceProducts(_insuranceProducts);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('InsuranceProductUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setInsuranceProduct(insuranceProductModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    //Add----
    const addInsuranceProductData = () => {
        try {


            addInsuranceProduct(insuranceProduct)
                .then(data => {

                    getInsuranceProductList()
                        .then(data => setInsuranceProducts(data))
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetInsuranceProductAddError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('InsuranceProductAddSuccess')}`, life: 3000 });
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setInsuranceProduct(insuranceProductModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteInsuranceProductData = () => {
        try {

            let _insuranceProduct = { ...insuranceProduct };

            if (insuranceProducts.some(val => val.id === _insuranceProduct.id)) {
                deleteInsuranceProduct(_insuranceProduct.id)
                    .then(data => {
                        //debugger;
                        // _insuranceProducts = _insuranceProducts.filter(val => val.id !== insuranceProduct.id);
                        getInsuranceProductList()
                            .then(data => setInsuranceProducts(data))
                            .catch(err => {
                                //throw new Error(`${err.data}`);
                                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('InsuranceProductDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setInsuranceProduct(insuranceProductModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setInsuranceProduct(insuranceProductModel);
            setInsuranceProductDeleteDialog(false);
        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }





    //**************************************
    //Logic fonks. START
    //**************************************

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }


    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < insuranceProducts.length; i++) {
                if (insuranceProducts[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setInsuranceProduct(insuranceProductModel);
            setSubmitted(false);
            setInsuranceProductSaveDialog(true);

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setInsuranceProduct(insuranceProductModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editInsuranceProduct = (insuranceProduct) => {
        try {
            //debugger;
            let _insuranceProduct = { ...insuranceProduct };
            setSubmitted(false);

            getInsuranceProductById(_insuranceProduct.id)
                .then(data => {
                    for (let index = 0; index < insuranceCompanys.length; index++) {
                        const element = insuranceCompanys[index];

                        if (element.code === data.insuranceCompanyId) {
                            setSelectInsuranceCompanysValue({ name: element.name, code: parseInt(element.code) });
                        }
                    }
                    setInsuranceProduct(data);
                })
                .catch(err => {
                    //debugger;
                    setInsuranceProduct(insuranceProductModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductUpdatedError') + ': ' + err.data}`, life: 5000 });

                    //throw new Error(`${err.message}`);
                })

            setInsuranceProductSaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('InsuranceProductUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }


    const confirmDeleteInsuranceProduct = (insuranceProduct) => {
        try {

            setInsuranceProduct(insuranceProduct);
            setInsuranceProductDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setInsuranceProduct(insuranceProductModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setInsuranceProduct(insuranceProductModel);
            setSubmitted(false);
            setInsuranceProductDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteInsuranceProduct-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setInsuranceProduct(insuranceProductModel);
            setSubmitted(false);
            setInsuranceProductSaveDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }


    //**------
    const onInputChange = (e, name) => {
        try {

            const val = (e.target && e.target.value) || '';
            let _insuranceProduct = { ...insuranceProduct };
            _insuranceProduct[`${name}`] = val;

            setInsuranceProduct(_insuranceProduct);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    //**************************************
    //btn fonks. END
    //**********************



    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteInsuranceProductDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteInsuranceProductData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('InsuranceProductList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const verifiedBodyTemplate = (rowData) => {

        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.elementer, 'text-pink-500 pi-times-circle': !rowData.elementer })}></i>;
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateInsuranceProductCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editInsuranceProduct(rowData)} />
                            )
                        }

                        return null;
                    })()}

                    {(() => {
                        if (JwtHelper.isRoleExist('DeleteInsuranceProductCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteInsuranceProduct(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }





    const leftToolbarTemplate = () => {
        if (JwtHelper.isRoleExist('CreateInsuranceProductCommand')) {
            return (

                <React.Fragment>
                    <div className="my-2">
                        <Button label={t('Add')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}
                    </div>
                </React.Fragment>
            )
        }
    }


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>
                                <div className="font-medium text-2xl text-900">{t('InsuranceProduct')}</div>
                                <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">Ana Sayfa</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('InsuranceProduct')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">{t('InsuranceProductList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-3 lg:mt-0">

                                <DbCurrencies />

                                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" /> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="card">


                        <Toast ref={toast} />

                        <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                        <DataTable ref={dt} value={insuranceProducts}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('ShowingToUsers')}
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column field="id" header={t('Id')} sortable headerStyle={{ width: '10%', minWidth: '3rem' }}></Column>
                            <Column field="insuranceCompany.name" header={t('InsuranceCompanyName')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="name" header={t('Name')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="productCode" header={t('ProductCode')} sortable headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                            <Column field="elementer" header={t('Elementer')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={verifiedBodyTemplate}></Column>
                            <Column header="#" body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '3rem' }}></Column>
                        </DataTable>

                        <Dialog visible={insuranceProductSaveDialog} style={{ width: '550px' }} header={t('InsuranceProductForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>

                            <div className="field">
                                <label htmlFor="insuranceCompanyId">{t('InsuranceCompany')} </label>
                                <Dropdown id="insuranceCompanyId" value={selectInsuranceCompanysValue} options={insuranceCompanys} onChange={(e) => setSelectInsuranceCompanysValue(e.value)} optionLabel="name" placeholder="Sigorta Şirketi" className={classNames({ 'p-invalid': !isValidatedInsuranceCompanyId })} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">{t('Name')} </label>
                                <InputText id="name" value={insuranceProduct.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                {submitted && !insuranceProduct.name && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="elementer">{t('Elementer')}</label>
                                <Dropdown id="elementer" value={selectYesNoValue} onChange={(e) => setSelectYesNoValue(e.value)} options={selectYesNoValues} optionLabel="name" placeholder="Durumu" required className={classNames({ 'p-invalid': !isValidatedElementer })}></Dropdown>
                            </div>
                            <div className="field">
                                <label htmlFor="productCode">{t('ProductCode')} </label>
                                <InputText id="productCode" value={insuranceProduct.productCode} onChange={(e) => onInputChange(e, 'productCode')} required className={classNames({ 'p-invalid': !isValidatedProductCode })} />
                                {submitted && !insuranceProduct.productCode && <small className="p-invalid">{t('Required')}</small>}
                            </div>
                        </Dialog>
                        <Dialog visible={insuranceProductDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteInsuranceProductDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {insuranceProduct && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>



                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(InsuranceProduct, comparisonFn);

